import { API } from 'utils/api.config';

export async function postWishlist(contentId, title) {
    return await API.post(`api/wishlist`, {
        contentId: [{ id: contentId }],
        title: title
    });
}

export async function addItem({ contentId, id }) {
    return await API.post(`api/wishlist/add`, {
        contentId: [
            {
                id: contentId
            }
        ],
        id: id
    });
}
export async function getWishlists(type) {
    return await API.get(`api/wishlist`, {
        params: {
            type
        }
    });
}
export async function getWishlistContentById(id) {
    return await API.get(`api/wishlist/${id}`);
}
