import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  items: [],
  loading: true,
  error: null
};
const auxiliarySlice = createSlice({
  name: 'auxiliary',
  initialState,
  reducers: {
    getAuxiliaryPage(state, action) {
      state.loading = true;
    },
    fetchAuxiliarySuccess(state, action) {
      state.items = action.payload;
      state.loading = false;
    },

    onFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});
export const { getAuxiliaryPage, fetchAuxiliarySuccess, onFailure } = auxiliarySlice.actions;
export default auxiliarySlice.reducer;
