import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import wishIcon from '../../assets/images/wish-icon.svg';
import { addFavourite } from 'redux/favourite/favourite.utils';
import { useDispatch, useSelector } from 'react-redux';
// ;
import { removeOnefavourites } from 'redux/favourite/favourite.slice';
const title = window.platform_info.title;

const AddToFavourite = ({ id, favourite, from }) => {
  const { theme } = useSelector((state) => state.theme);
  const [animation, setAnmation] = React.useState(false);
  const [favourites, setFavourites] = React.useState(favourite);
  const [text, setText] = React.useState('Add to Favourites');
  React.useEffect(() => {
    setFavourites(favourite);
  }, [favourite]);

  const handleAddToFavourite = () => {
    setAnmation(false);
    const tempToken = JSON.parse(localStorage.getItem(`access_token_temp_${title}`));
    if (tempToken) {
      window.location.replace('/login');
    } else {
      addFavourite(id)
        .then(function (response) {
          if (response === undefined) throw 'Item already added';
          setAnmation(true);
          setFavourites(true);
          setText('Remove from Favourites');
        })
        .catch(function (error) {});
    }
  };
  const dispatch = useDispatch();
  const handleRemoveFavourite = () => {
    setFavourites(false);
    dispatch(removeOnefavourites(id));
    setText('Add to Favourites');
  };
  if (from === 'carouselHome') {
    return (
      <>
        {/* <div
          className="add-fav-blur"
          onClick={favourites ? handleRemoveFavourite : handleAddToFavourite}></div> */}
        {/* <p onClick={favourites ? handleRemoveFavourite : handleAddToFavourite}>{text}</p> */}
        <StyledFavouriteIcon theme={theme}>
          <Button
            title="favourite"
            variant="transparent"
            className={animation ? 'wish-btn animation' : 'wish-btn'}
            style={favourites === true ? { background: 'var(--hover-color)' } : null}
            fontSize={[14, 18]}
            onClick={favourites ? handleRemoveFavourite : handleAddToFavourite}></Button>
        </StyledFavouriteIcon>
      </>
    );
  }
  return (
    <StyledFavourite theme={theme}>
      <Button
        title="favourite"
        variant="transparent"
        className={animation ? 'wish-btn animation' : 'wish-btn'}
        style={favourites === true ? { background: 'var(--hover-color)' } : null}
        fontSize={[14, 18]}
        onClick={favourites ? handleRemoveFavourite : handleAddToFavourite}></Button>
    </StyledFavourite>
  );
};
const StyledFavouriteIcon = styled.div`
  .wish-btn {
    max-width: 18px;
    max-height: 18px;
    mask: url(${wishIcon});
    mask-size: contain;
    mask-repeat: no-repeat;
    background: ${(props) => (props.theme === 'light' ? 'var(--black-pearl)' : 'var(--off-white)')};
    display: inline-block;
    @media (min-width: 768px) {
      :hover {
        background: var(--hover-color);
      }
    }
  }
`;
const StyledFavourite = styled.div`
  .animation {
    animation: shake 0.5s;
    animation-iteration-count: 1s;
  }
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
  .wish-btn {
    max-width: 22px;
    max-height: 18px;
    mask: url(${wishIcon});
    mask-size: contain;
    mask-repeat: no-repeat;
    background: ${(props) => (props.theme === 'light' ? 'var(--black-pearl)' : 'var(--off-white)')};
    display: inline-block;
    margin-top: -7px;
    @media (min-width: 768px) {
      :hover {
        background: var(--hover-color);
      }
    }
  }
`;

export default AddToFavourite;
