import { API } from 'utils/api.config';

// export async function getContentById(id) {
//   return await API.get(`api/content/${id}`);
// }

export async function getContentById(id) {
  return await API.get(`api/v2/content/${id}`);
}

// export async function getAlbumById(id) {
//   return await API.get(`api/content/album/${id}`);
// }
export async function getAlbumById(id) {
  return await API.get(`api/v2/content/album/${id}`);
}

export async function getArtistAudioById(id, page, type) {
  return await API.get(`api/artist/page/details/${id}?page=${page}&type=${type}`);
}

export async function getArtistAudioBySlug(slug, page, type) {
  return await API.get(`api/artist/page/details/slug/${slug}?page=${page}&type=${type}`);
}

export async function getAudioBySlug(slug) {
  return await API.get(`api/v2/content/slug/${slug}`)
}

export async function getAlbumBySlug(slug) {
  return await API.get(`api/v2/content/album/slug/${slug}`)
}