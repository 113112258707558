import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getBanner, onSuccess, onFailure } from './banner.slice';
import { getBannerApi } from './banner.utils';
export function* fetchBanner() {
    try {
        const { data } = yield call(getBannerApi);

        yield put(onSuccess(data));
    } catch (error) {
        yield put(onFailure(error.toString()));
    }
}
export function* onFetchBanner() {
    yield takeLatest(getBanner.toString(), fetchBanner);
}
export function* bannerSagas() {
    yield all([call(onFetchBanner)]);
}

// function* fetchPosts(action) {
//   if (action.type === WP_POSTS.LIST.REQUESTED) {
//     try {
//       const response = yield call(wpGet, {
//         model: WP_POSTS.MODEL,
//         contentType: APPLICATION_JSON,
//         query: action.payload.query,
//       });
//       if (response.error) {
//         yield put({
//           type: WP_POSTS.LIST.FAILED,
//           payload: response.error.response.data.msg,
//         });
//         return;
//       }
//       yield put({
//         type: WP_POSTS.LIST.SUCCESS,
//         payload: {
//           posts: response.data,
//           total: response.headers['x-wp-total'],
//           query: action.payload.query,
//         },
//         view: action.view,
//       });
//     } catch (e) {
//       yield put({ type: WP_POSTS.LIST.FAILED, payload: e.message });
//     }
//   }
