export default {
  apiKey: window.firebase.REACT_APP_FIREBASE_API_KEY,
  authDomain: window.firebase.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: window.firebase.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: window.firebase.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: window.firebase.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: window.firebase.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: window.firebase.REACT_APP_FIREBASE_APP_ID,
  measurementId: window.firebase.REACT_APP_FIREBASE_MEASUREMENT_ID
};
