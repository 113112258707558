import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
  getArtist,
  fetchArtistSuccess,
  getContent,
  fetchContentSuccess,
  topArtist,
  topArtistSuccess,
  topArtistAll,
  topArtistAllSuccess,
  onFailure,
  getArtistSearch,
  getArtistSearchSuccess,
  getArtistSearchFailure
} from './artist.slice';
import {
  getArtistDetailsById,
  getContentById,
  TopArtistApi,
  TopArtistSeemore,
  getContentBySearch,
  getArtistDetailsBySlug,
  getContentBySlug
} from './artist.utils';
export function* fetchArtist({ payload }) {
  try {
    const { data } = yield call(getArtistDetailsBySlug, payload);

    yield put(fetchArtistSuccess(data));
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}

export function* fetchArtistContent({ payload: { slug, page, type } }) {
  try {
    const { data } = yield call(getContentBySlug, slug, page, type);

    yield put(fetchContentSuccess(data));
  } catch (error) {
    // yield put(onFailure(error.toString()));
  }
}
export function* fetchTopArtist() {
  try {
    const { data } = yield call(TopArtistApi);

    yield put(topArtistSuccess(data));
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}
export function* fetchTopArtistAll() {
  try {
    const { data } = yield call(TopArtistSeemore);

    yield put(topArtistAllSuccess(data));
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}
export function* fetchContentSearch({ payload: { id, page, title } }) {
  try {
    const { data } = yield call(getContentBySearch, id, page, title);

    yield put(getArtistSearchSuccess(data));
  } catch (error) {
    yield put(getArtistSearchFailure(error.toString()));
  }
}
export function* onFetchArtist() {
  yield takeLatest(getArtist.toString(), fetchArtist);
}
export function* onfetchArtistContent() {
  yield takeLatest(getContent.toString(), fetchArtistContent);
}
export function* onFetchTopArtist() {
  yield takeLatest(topArtist.toString(), fetchTopArtist);
}
export function* onFetchTopArtistAll() {
  yield takeLatest(topArtistAll.toString(), fetchTopArtistAll);
}
export function* onfetchContentSearch() {
  yield takeLatest(getArtistSearch.toString(), fetchContentSearch);
}
export function* artistSagas() {
  yield all([
    call(onFetchArtist),
    call(onfetchArtistContent),
    call(onFetchTopArtist),
    call(onFetchTopArtistAll),
    call(onfetchContentSearch)
  ]);
}
