import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: null,
  loading: true,
  results: null,
  artists: [],
  audios: [],
  videos: []
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    getSearchResults(state, action) {
      state.loading = true;
      // state.results = null;
    },
    fetchSearchSuccess(state, action) {
      state.loading = false;
      state.results = action.payload;
      state.artists = action.payload?.artists !== null && [
        ...state.artists,
        ...action.payload?.artists.items
      ];
      state.audios = action.payload?.audios !== null && [
        ...state.audios,
        ...action.payload?.audios?.items
      ];
      state.videos = action.payload?.videos !== null && [
        ...state.videos,
        ...action.payload?.videos?.items
      ];
    },
    onFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { getSearchResults, fetchSearchSuccess, onFailure } = searchSlice.actions;
export default searchSlice.reducer;
