import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  items: [],
  totalPage: 1,
  page: 1,
  count: 10,
  loading: true,
  error: null
};
const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    getAllFeeds(state, action) {
      state.loading = true;
    },
    onSuccess(state, action) {
      state.loading = false;
      state.items = [...state.items, ...action.payload.items];
      state.totalPage = action.payload.totalPage;
      state.page = action.payload.page;
      state.count = action.payload.count;
    },
    onFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});
export const { getAllFeeds, onSuccess, onFailure } = feedSlice.actions;
export default feedSlice.reducer;
