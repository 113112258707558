import { API_URL } from 'utils/api-url.config';
import { API } from 'utils/api.config';
import axios from 'axios';

export const getOtpSuccess = async (data, headers) => {
  try {
    const response = await axios.post(`${API_URL}otp/api/v1/verify/otp`, data, {
      headers
    });
  
    return { ...response?.data?.Data };
  } catch (error) {
    return "400";
  }
};
export const createUserProfileDocument = async (userAuth, additioalData, fromOtpBd) => {
  if (!userAuth) {
    return;
  }
  let user = {};
  if (fromOtpBd) {
    user = {
      ...userAuth,
      mobile: userAuth.phoneNumber,
      country_code: additioalData?.country_code || null,
      mobile_number: additioalData?.mobile_number || null,
      msisdn: additioalData?.msisdn || null,
      platform: window.platform_info.api_key
    };
  } else {
    const [{ uid, photoURL, displayName, email, phoneNumber, providerId }] = userAuth[
      'providerData'
    ];

    user = {
      id: uid,
      name: displayName,
      email,
      mobile: phoneNumber,
      avatar: photoURL,
      site: providerId.replace('.com', ''),
      country_code: additioalData?.country_code || null,
      mobile_number: additioalData?.mobile_number || null,
      msisdn: additioalData?.msisdn || null,
      platform: window.platform_info.api_key
    };
  }

  const response = await axios.post(`${API_URL}auth/token`, JSON.stringify(user), {
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json'
    }
  });
  return {
    user,
    fromOtp: fromOtpBd,
    ...response.data
  };
};

export const createUserProfileDocumentCoupon = async () => {
  const user = {
    id: null,
    name: 'Coupon User',
    email: '',
    mobile: '',
    avatar: '',
    platform: window.platform_info.api_key
  };

  return {
    user
  };
};

export const getUserProfileDocument = async () => {
  const response = await API.get(`api/profile`);
  return {
    ...response.data
  };
};

export const getUserProfileDocumentv2 = async () => {
  const response = await API.get(`api/v2/profile`);
  return {
    ...response.data
  };
};

export const getUserProfileTvod = async () => {
  const response = await API.get(`ipn/v1/tvod/profile`);
  return {
    ...response.data
  };
};

export const getUserLocationDocument = async () => {
  const response = await axios.get(`https://ip2c.org/self`);
  return {
    ...response
  };
};

/////////////////

// export const checkLoggedIn = async preloadedState => {
//   const response = await fetch('/api/session');
//   const { user } = await response.json();
//   let preloadedState = {};
//   if (user) {
//     preloadedState = {
//       session: user
//     };
//   }
//   return preloadedState;
// };
// export function authHeader() {
//   // return authorization header with jwt token
//   let user = JSON.parse(localStorage.getItem('user'));

//   if (user && user.token) {
//       return { 'Authorization': 'Bearer ' + user.token };
//   } else {
//       return {};
//   }
// }
// headers: authHeader()
