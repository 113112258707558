import UserActionTypes from './user.types';

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword
});
export const checkUserLocation = () => ({
  type: UserActionTypes.GET_USER_LOCATION
});
export const checkUserLocationSuccess = (data, status) => ({
  type: UserActionTypes.GET_USER_LOCATION_SUCCESS,
  payload: { data, status }
});
export const checkUserLocationFailure = (error) => ({
  type: UserActionTypes.GET_USER_LOCATION_FAILURE,
  payload: error
});
export const getProfileStart = () => ({
  type: UserActionTypes.GET_PROFILE
});
export const getProfileSuccess = (userProfile) => ({
  type: UserActionTypes.GET_PROFILE_SUCCESS,
  payload: userProfile
});
export const getProfileFailure = (error) => ({
  type: UserActionTypes.GET_PROFILE_FAILURE,
  payload: error
});
export const couponSignInStart = () => ({
  type: UserActionTypes.COUPON_SIGN_IN_START
});
export const googleSignInStart = () => ({
  type: UserActionTypes.GOOGLE_SIGN_IN_START
});
export const facebookSignInStart = () => ({
  type: UserActionTypes.FACEBOOK_SIGN_IN_START
});
export const phoneNumberSignInStart = (phoneNumber) => ({
  type: UserActionTypes.PHONE_NUMBER_SIGN_IN_START,
  payload: phoneNumber
});

export const phoneNumberSignInStartBd = (phoneNumber) => ({
  type: UserActionTypes.PHONE_NUMBER_SIGN_IN_START_BD,
  payload: phoneNumber
});
export const isOTPVerificationLoading = () => ({
  type: UserActionTypes.IS_OTP_VERIFICATION_LOADING
})
export const otpVerificationError = () => ({
  type: UserActionTypes.OTP_VERIFICATION_ERROR
})
export const otpVerificationSuccess = () => ({
  type: UserActionTypes.OTP_VERIFICATION_SUCCESS
})
export const setVerficationError = () => ({
  type: UserActionTypes.SET_VERIFICATION_ERROR
})
export const confirmVerification = (resultAndCode) => ({
  type: UserActionTypes.PHONE_NUMBER_VERIFICATION_CONFIRMATION_START,
  payload: resultAndCode
});
export const sentOTPSuccess = (phoneNumber, details) => ({
  type: UserActionTypes.SENT_OTP_SUCCESS,
  payload: {
    phoneNumber,
    details
  }
});
export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user
});
export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION
});
export const unAuthorizedUser = () => ({
  type: UserActionTypes.ACTION_RESOLVE
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START
});
export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS
});
export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error
});
export const signUpStart = (userCredentials) => ({
  type: UserActionTypes.SIGN_UP_START,
  payload: userCredentials
});
export const signUpSuccess = ({ user, additionalData }) => ({
  type: UserActionTypes.SIGN_UP_SUCCESS,
  payload: { user, additionalData }
});
export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error
});
