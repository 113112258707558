import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
  getResponse,
  fetchResponseSuccess,
  getResponseByCountry,
  fetchResponseSuccessByCountry,
  getLocation,
  getLocationSuccess,
  onFailure
} from './payment.slice';
import { getResponsePayment, getResponsePaymentByCountry, getUserLocation } from './payment.utils';
export function* fetchPayment() {
  try {
    const { data } = yield call(getResponsePayment);

    yield put(fetchResponseSuccess(data));
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}
export function* fetchPaymentByCountry({ payload }) {
  try {
    const { data } = yield call(getResponsePaymentByCountry, payload);

    yield put(fetchResponseSuccessByCountry(data));
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}
export function* fetchLocation() {
  try {
    const { data } = yield call(getUserLocation);

    yield put(getLocationSuccess(data));
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}
export function* onFetchPayment() {
  yield takeLatest(getResponse.toString(), fetchPayment);
}
export function* onFetchPaymentByCountry() {
  yield takeLatest(getResponseByCountry.toString(), fetchPaymentByCountry);
}
export function* onfetchLocation() {
  yield takeLatest(getLocation.toString(), fetchLocation);
}
export function* paymentSagas() {
  yield all([call(onFetchPayment), call(onFetchPaymentByCountry), call(onfetchLocation)]);
}
