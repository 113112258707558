export function time_convert(totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} m ${seconds} s`;
}

export function time_convert_min_to_sec(num) {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    return `${hours} h ${minutes} m`;
}
export function formatTime(seconds) {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad(date.getUTCSeconds());
    if (hh) {
        return `${hh}h ${pad(mm)}m ${ss}s`;
    }
    return `${mm}m ${ss}s`;
}

function pad(string) {
    return ('0' + string).slice(-2);
}
