import * as actionTypes from './category.types';
export function fetchCategoriesStart(pageAndType) {
    return {
        type: actionTypes.FETCH_CATEGORIES_START,
        payload: pageAndType
    };
}
export function fetchCategoriesSuccess(dataAndType) {
    return {
        type: actionTypes.FETCH_CATEGORIES_SUCCESS,
        payload: dataAndType
    };
}
export function fetchCategoriesFailure(error) {
    return {
        type: actionTypes.FETCH_CATEGORIES_FAILURE,
        payload: error
    };
}
export function getCategory(id) {
    return {
        type: actionTypes.FETCH_CATEGORY_REQUEST,
        payload: id
    };
}
export function getCategorySlug(slug) {
    return {
        type: actionTypes.FETCH_CATEGORY_SLUG_REQUEST,
        payload: slug
    };
}
export function fetchCategorySuccess(data) {
    return {
        type: actionTypes.FETCH_CATEGORY_SUCCESS,
        payload: data
    };
}
export function fetchCategoryFailure(error) {
    return {
        type: actionTypes.FETCH_CATEGORY_FAILURE,
        payload: error
    };
}
