import { API } from 'utils/api.config';

export async function getArtistDetailsById(id) {
  return await API.get(`/api/artist/page/${id}`);
}
export async function getArtistDetailsBySlug(slug) {
  return await API.get(`/api/artist/page/slug/${slug}`);
}
export async function TopArtistApi() {
  return await API.get(`/api/artist/popular/list?page=1`);
}
export async function TopArtistSeemore() {
  return await API.get(`api/artist/popular/seemore`);
}

export async function getContentById(id, page, type) {
  return await API.get(`api/artist/page/details/${id}?page=${page}&type=${type}`);
}
export async function getContentBySlug(slug, page, type) {
  return await API.get(`api/artist/page/details/slug/${slug}?page=${page}&type=${type}`);
}
export async function getContentBySearch(id, page, title) {
  return await API.get(`/api/artist/content/search`, {
    params: {
      artistId: id,
      page,
      title
    }
  });
}
