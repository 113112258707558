import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  banner: null, // series or videos general info
  tracks: null, // loads all "content" field data for series from api
  currentTrack: null, // loads "content" fields [0] index to play.
  currentTrackIdx: 0,
  resumedTrack: null,
  playing: false,
  showPlayer: false,
  error: null,
  loading: true,
  trialPath: null,
  allTracks: null,
  vastTag: null
};

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    getContent(state, action) {
      state.loading = true;
      state.tracks = null;
      state.banner = null;
    },
    fetchContentSuccess(state, action) {
      state.loading = false;
      state.banner = action.payload;
      state.tracks = [action.payload];
      state.currentTrack = action.payload;
    },
    getContentSlug(state, action) {
      state.loading = true;
      state.tracks = null;
      state.banner = null;
    },
    fetchContentBySlugSuccess(state, action) {
      state.loading = false;
      state.banner = action.payload;
      state.tracks = [action.payload];
      state.currentTrack = action.payload;
    },
    getSeries(state, action) {
      state.loading = true;
      state.tracks = null;
      state.banner = null;
    },
    fetchSeriesSuccess(state, action) {
      state.loading = false;
      state.tracks = action.payload.contents ? action.payload.contents : null;
      state.banner = action.payload.seriesInfo;
      state.currentTrack = action.payload.contents[0]
        ? action.payload.contents[0].contents[0]
        : null;
    },
    getSeriesSlug(state, action) {
      state.loading = true;
      state.tracks = null;
      state.banner = null;
    },
    fetchSeriesBySlugSuccess(state, action) {
      state.loading = false;
      state.tracks = action.payload.contents ? action.payload.contents : null;
      state.banner = action.payload.seriesInfo;
      state.currentTrack = action.payload.contents[0]
        ? action.payload.contents[0].contents[0]
        : null;
    },
    getCatchup(state, action) {
      state.loading = true;
      state.tracks = null;
      state.banner = null;
    },
    fetchCatchupSuccess(state, action) {
      state.loading = false;
      state.tracks = action.payload.contents[0] ? action.payload.contents : null;
      state.banner = action.payload.catchUpInfo;
      state.currentTrack = action.payload.catchUpInfo;
      // state.currentTrack = action.payload.catchUpInfo;
      state.trialPath = action.payload.catchUpInfo.path,
      state.allTracks = [action.payload.catchUpInfo, ...action.payload.contents];
    },
    onFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    openPlayer(state, action) {
      state.showPlayer = true;
    },
    closePlayer(state, action) {
      state.showPlayer = false;
      state.playing = false;
    },
    playPause(state, action) {
      state.playing = !state.playing;
    },
    resume(state, action) {
      state.resumedTrack = state.currentTrack;
      state.playing = false;
    },
    changeTrack(state, { payload: { episodeIndex, seriesIndex } }) {
      state.currentTrack = state.tracks && state.tracks[seriesIndex]?.contents[episodeIndex];
      // state.currentTrackIdx = action.payload;
      // state.playing = true;
    },
    changeCatchupTrack(state, action) {
      state.currentTrack = state.allTracks?.length && state.allTracks[action.payload.episodeIndex]
    },
    setVastTag(state, action) {
      state.vastTag = action.payload;
    }
  }
});

export const {
  getContent,
  fetchContentSuccess,
  getSeries,
  fetchSeriesSuccess,
  getCatchup,
  fetchCatchupSuccess,
  onFailure,
  openPlayer,
  closePlayer,
  playPause,
  resume,
  changeTrack,
  changeCatchupTrack,
  setVastTag,
  getContentSlug,
  fetchContentBySlugSuccess,
  getSeriesSlug,
  fetchSeriesBySlugSuccess
} = videoSlice.actions;
export default videoSlice.reducer;
