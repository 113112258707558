import axios from "axios";
import { API_URL } from "./api-url.config";
import { API } from "./api.config";
import { PAYMENT_URL } from "./payment.config";

export async function subscribeHandler() {
    const title = window.platform_info.title;
    const accessToken = JSON.parse(localStorage.getItem(`access_token_r_${title}`));

    try {
        const response = await axios.post(`${API_URL}auth/token/shortlived`, {
            token: accessToken
        })
        const shortLived = await response?.data.token;
        const encodedToken = btoa(shortLived);
        const platformRes = await API.get(`api/platforminfo`);
        const domain = await platformRes?.data.domain
        // console.log(domain);
        if (shortLived) {
            window.location.href = `${PAYMENT_URL}?hash=${encodedToken}&url=${domain}`
        }
    } catch (error) {
        console.log("Error getting token or platform", error);
    }
}

export async function tvodHandler({ contentId, isTvod}) {
    const id = Number(contentId);
    const title = window.platform_info.title;
    const accessToken = JSON.parse(localStorage.getItem(`access_token_r_${title}`));

    try {
        const response = await axios.post(`${API_URL}auth/token/shortlived`, {
            token: accessToken
        })
        const shortLived = await response?.data.token;
        const encodedToken = btoa(shortLived);
        const platformRes = await API.get(`api/platforminfo`);
        const domain = await platformRes?.data.domain
        // console.log(domain);
        if (shortLived) {
            window.location.href = `${PAYMENT_URL}?hash=${encodedToken}&url=${domain}&content_id=${id}&tvod=${isTvod}`
        }
    } catch (error) {
        console.log("Error getting token or platform", error);
    }
}