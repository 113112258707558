import * as actionTypes from './category.types';

const initialState = {
  all: {
    items: [],
    totalPage: 1,
    page: 1,
    count: 10
  },
  video: {
    items: [],
    totalPage: 1,
    page: 1,
    count: 10
  },
  original: {
    items: [],
    totalPage: 1,
    page: 1,
    count: 10
  },
  audio: {
    items: [],
    totalPage: 1,
    page: 1,
    count: 10
  },
  selectedCategory: {
    items: [],
    totalPage: 1,
    page: 1,
    count: 10,
    orientationType: 'horizontal'
  },
  loading: true,
  error: null
};
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CATEGORIES_START:
    case actionTypes.FETCH_CATEGORY_REQUEST:
    case actionTypes.FETCH_CATEGORY_SLUG_REQUEST:
      if (action.payload.page === 1) {
        return {
          ...state,
          error: null,
          loading: true,
          selectedCategory: {
            items: [],
            totalPage: 1,
            page: 1,
            count: 10,
            orientationType: 'horizontal'
          }
        };
      } else {
        return {
          ...state,
          error: null,
          loading: true
        };
      }

    case actionTypes.FETCH_CATEGORIES_SUCCESS:
      const { data, type } = action.payload;
      // const categoryType = type ? type : 'all';
      return {
        ...state,
        loading: false,
        [type]: {
          items: [...state[type].items, ...data.items],
          totalPage: data.totalPage,
          page: data.page,
          count: data.count
        }
      };
    case actionTypes.FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCategory: {
          items: [...state.selectedCategory.items, ...action.payload.contents.items],
          totalPage: action.payload.contents.totalPage,
          page: action.payload.contents.page,
          count: action.payload.contents.count,
          orientationType: action.payload.orientationType
        }
      };
    case actionTypes.FETCH_CATEGORIES_FAILURE:
    case actionTypes.FETCH_CATEGORY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
