import { takeLatest, takeEvery, put, all, call } from 'redux-saga/effects';
import {
  addItemTofavourite,
  addItemTofavouriteSuccess,
  addItemTofavouriteFailure,
  fetchfavourites,
  fetchfavouritesSuccess,
  fetchfavouritesFailure,
  removeOnefavourites,
  removeOnefavouritesSuccess,
  removeOnefavouritesFailure,
  removeAllfavourites,
  removeAllfavouritesSuccess,
  removeAllfavouritesFailure,
  seeAllfavourites,
  seeAllfavouritesSuccess,
  seeAllfavouritesFailure,
  getSingleFavourite,
  getSingleFavouriteSuccess,
  getSingleFavouriteFailure
} from './favourite.slice';
import {
  getAllFavourite,
  addFavourite,
  removeAllFavourite,
  removeFavourite,
  seeAllFavourite,
  getFavouriteTrue
} from './favourite.utils';

export function* fetchAllFavourite({ payload: { type, page } }) {
  try {
    const { data } = yield call(getAllFavourite, type, page);
    yield put(fetchfavouritesSuccess({ data, type }));
  } catch (error) {
    yield put(fetchfavouritesFailure(error.toString()));
  }
}

export function* postFavorites({ payload }) {
  try {
    const { data } = yield call(addFavourite, payload);

    yield put(addItemTofavouriteSuccess(data));
  } catch (error) {
    yield put(addItemTofavouriteFailure(error.toString()));
  }
}
export function* deleteFavorite({ payload }) {
  try {
    const { data } = yield call(removeFavourite, payload);

    yield put(removeOnefavouritesSuccess(data));
  } catch (error) {
    yield put(removeOnefavouritesFailure(error.toString()));
  }
}
export function* deleteAllFavorite({ payload }) {
  try {
    const { data } = yield call(removeAllFavourite, payload);

    yield put(removeAllfavouritesSuccess(data));
  } catch (error) {
    yield put(removeAllfavouritesFailure(error.toString()));
  }
}
export function* seeAllFavouriteGet({ payload }) {
  try {
    const { data } = yield call(seeAllFavourite, payload);

    yield put(seeAllfavouritesSuccess(data));
  } catch (error) {
    yield put(seeAllfavouritesFailure(error.toString()));
  }
}

export function* fetchSingleFavourite({ payload }) {
  try {
    const { data } = yield call(getFavouriteTrue, payload);

    yield put(getSingleFavouriteSuccess(data));
  } catch (error) {
    yield put(getSingleFavouriteFailure(error.toString()));
  }
}

export function* onFetchAllFavourite() {
  yield takeEvery(fetchfavourites.toString(), fetchAllFavourite);
}
export function* onAddToFavourite() {
  yield takeLatest(addItemTofavourite.toString(), postFavorites);
}
export function* onRemoveOneFavourite() {
  yield takeLatest(removeOnefavourites.toString(), deleteFavorite);
}
export function* onRemoveAllFavourite() {
  yield takeLatest(removeAllfavourites.toString(), deleteAllFavorite);
}
export function* onSeeAllFavourite() {
  yield takeLatest(seeAllfavourites.toString(), seeAllFavouriteGet);
}
export function* onfetchSingleFavourite() {
  yield takeLatest(getSingleFavourite.toString(), fetchSingleFavourite);
}
export function* favouriteSagas() {
  yield all([
    call(onFetchAllFavourite),
    call(onAddToFavourite),
    call(onRemoveOneFavourite),
    call(onRemoveAllFavourite),
    call(onSeeAllFavourite),
    call(onfetchSingleFavourite)
  ]);
}
