import styled from 'styled-components';
import { space, color, layout, flexbox, compose } from 'styled-system';

export const Box = styled.div(
    {
        boxSizing: 'border-box',
        margin: 0,
        minWidth: 0
    },
    compose(space, color, layout, flexbox)
    // props => props.css
);

Box.displayName = 'Box';
