export let API_URL = '/';
const dev = {
  API_URL: process.env.REACT_APP_API_URL
};

const prod = {
  API_URL: 'https://api.rockstreamer.com/'
};
if (process.env.REACT_APP_STAGE === 'production') {
  API_URL = prod.API_URL;
} else {
  API_URL = dev.API_URL;
}
