import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  video: [],
  seeAll: [],
  audio: [],
  favourite: null,
  loading: true,
  error: null,
  refresh: false
};
const favouriteSlice = createSlice({
  name: 'favourite',
  initialState,
  reducers: {
    addItemTofavourite(state, action) {
      state.loading = true;
    },
    addItemTofavouriteSuccess(state, action) {
      state.loading = false;
    },
    addItemTofavouriteFailure(state, action) {
      state.error = action.payload;
    },
    fetchfavourites(state, action) {
      state.loading = true;
      // state.items = [];
    },
    fetchfavouritesSuccess(state, action) {
      state.loading = false;

      state[action.payload.type] = action.payload.data;
    },
    fetchfavouritesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    removeOnefavourites(state, action) {
      state.refresh = true;
    },
    removeOnefavouritesSuccess(state, action) {
      state.refresh = false;
    },
    removeOnefavouritesFailure(state, action) {
      state.refresh = false;
      state.error = action.payload;
    },
    removeAllfavourites(state, action) {
      state.refresh = true;
    },
    removeAllfavouritesSuccess(state, action) {
      state.refresh = false;
    },
    removeAllfavouritesFailure(state, action) {
      state.refresh = false;
      state.error = action.payload;
    },
    seeAllfavourites(state, action) {
      state.loading = true;
    },
    seeAllfavouritesSuccess(state, action) {
      state.loading = false;

      state.seeAll = action.payload;
    },
    seeAllfavouritesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getSingleFavourite(state, action) {
      state.loading = true;
    },
    getSingleFavouriteSuccess(state, action) {
      state.loading = false;
      state.favourite = action.payload.favorite;
    },
    getSingleFavouriteFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});
export const {
  addItemTofavourite,
  addItemTofavouriteSuccess,
  addItemTofavouriteFailure,
  fetchfavourites,
  fetchfavouritesSuccess,
  fetchfavouritesFailure,
  removeOnefavourites,
  removeOnefavouritesSuccess,
  removeOnefavouritesFailure,
  removeAllfavourites,
  removeAllfavouritesSuccess,
  removeAllfavouritesFailure,
  seeAllfavourites,
  seeAllfavouritesSuccess,
  seeAllfavouritesFailure,
  getSingleFavourite,
  getSingleFavouriteSuccess,
  getSingleFavouriteFailure
} = favouriteSlice.actions;
export default favouriteSlice.reducer;
