import UserActionTypes from './user.types';

const INITIAL_STATE = {
  status: 'pending',
  currentUser: null,
  userProfile: {},
  isOTPSent: false,
  phoneNumber: null,
  error: null,
  details: '',
  isOTPVerifyLoading: false,
  otpVerifyError: false,
  otpVerifySucess: false
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SENT_OTP_SUCCESS:
      return {
        ...state,
        isOTPSent: true,
        phoneNumber: action.payload.phoneNumber,
        details: action.payload.details,
        error: null
      };

    case UserActionTypes.IS_OTP_VERIFICATION_LOADING:
      return {
        ...state,
        isOTPVerifyLoading: true
      };
    case UserActionTypes.OTP_VERIFICATION_ERROR:
      return {
        ...state,
        isOTPVerifyLoading: false,
        otpVerifyError: true,
      };
    case UserActionTypes.OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        isOTPVerifyLoading: false,
        otpVerifySucess: true,
      };
    case UserActionTypes.SET_VERIFICATION_ERROR: 
      return {
        ...state,
        otpVerifyError: false,
        isOTPSent: false
      }
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        status: 'resolved',
        isOTPSent: false,
        error: null
      };
    case UserActionTypes.ACTION_RESOLVE:
      return {
        ...state,
        status: 'resolved',
        error: null
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        isOTPSent: false,
        error: null
      };
    case UserActionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        status: 'resolved',
        error: null
      };
    case UserActionTypes.GET_USER_LOCATION_SUCCESS:
      return {
        ...state,
        userLocation: action.payload,
        status: 'resolved',
        error: null
      };
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: 'resolved'
      };
    case UserActionTypes.GET_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: 'resolved'
      };
    case UserActionTypes.GET_USER_LOCATION_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: 'resolved'
      };
    default:
      return state;
  }
};

export default userReducer;
