import mixpanel from 'mixpanel-browser';
const token = window?.mix_panel?.token || '73d6f4c1e5dummy';
mixpanel.init(token);

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  }
};

export let Mixpanel = actions;
