import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
  getChannels,
  onSuccess,
  onFailure,
  getChannelsSeeMore,
  onSuccessSeeMore
} from './channel.slice';
import { getChannelsApi, getChannelsSeeMoreApi } from './channel.utils';
export function* fetchChannels() {
  try {
    const { data } = yield call(getChannelsApi);

    yield put(onSuccess(data));
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}
export function* fetchChannelsSeeMore() {
  try {
    const { data } = yield call(getChannelsSeeMoreApi);

    yield put(onSuccessSeeMore(data));
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}
export function* onFetchChannels() {
  yield takeLatest(getChannels.toString(), fetchChannels);
}
export function* onFetchChannelsSeeMore() {
  yield takeLatest(getChannelsSeeMore.toString(), fetchChannelsSeeMore);
}
export function* channelSagas() {
  yield all([call(onFetchChannels), call(onFetchChannelsSeeMore)]);
}

// function* fetchPosts(action) {
//   if (action.type === WP_POSTS.LIST.REQUESTED) {
//     try {
//       const response = yield call(wpGet, {
//         model: WP_POSTS.MODEL,
//         contentType: APPLICATION_JSON,
//         query: action.payload.query,
//       });
//       if (response.error) {
//         yield put({
//           type: WP_POSTS.LIST.FAILED,
//           payload: response.error.response.data.msg,
//         });
//         return;
//       }
//       yield put({
//         type: WP_POSTS.LIST.SUCCESS,
//         payload: {
//           posts: response.data,
//           total: response.headers['x-wp-total'],
//           query: action.payload.query,
//         },
//         view: action.view,
//       });
//     } catch (e) {
//       yield put({ type: WP_POSTS.LIST.FAILED, payload: e.message });
//     }
//   }
