import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    items: [],
    loading: true,
    error: null
};
const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {
        getBanner(state, action) {
            state.loading = true;
        },
        onSuccess(state, action) {
            state.items = action.payload;
            state.loading = false;
        },
        onFailure(state, action) {
            state.error = action.payload;
            state.loading = false;
        }
    }
});
export const { getBanner, onSuccess, onFailure } = bannerSlice.actions;
export default bannerSlice.reducer;
