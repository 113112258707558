export let PAYMENT_URL = '/';
const dev = {
  PAYMENT_URL: process.env.REACT_APP_PAYMENT_URL
};

const prod = {
  PAYMENT_URL: 'https://payment.rockstreamer.com'
};
if (process.env.REACT_APP_STAGE === 'production') {
  PAYMENT_URL = prod.PAYMENT_URL;
} else {
  PAYMENT_URL = dev.PAYMENT_URL;
}
