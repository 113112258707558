import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  InstapaperIcon,
  WhatsappShareButton,
  WhatsappIcon,
  ViberShareButton,
  ViberIcon
} from 'react-share';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import shareIcon from '../../../assets/images/share-icon.svg';
import { Button } from 'components/Button';
const Share = ({ shareUrl, title, className }) => {
  const [share, setShare] = React.useState(false);
  const { theme } = useSelector((state) => state.theme);
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShare(false);
    }
  };
  const handleShareOpen = () => {
    setShare(!share);
  };
  return (
    <div style={{ position: 'relative' }} className={className} ref={containerRef}>
      <ShareIconWrapper theme={theme}>
        <Button variant="transparent" className="share-btn" onClick={handleShareOpen}></Button>
      </ShareIconWrapper>

      {share && (
        <Wrapper>
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="Demo__some-network__share-button">
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <InstapaperShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button">
            <InstapaperIcon size={32} round />
          </InstapaperShareButton>
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="Demo__some-network__share-button">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <ViberShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button">
            <ViberIcon size={32} round />
          </ViberShareButton>
        </Wrapper>
      )}
    </div>
  );
};

export default Share;

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 100%;
`;
const ShareIconWrapper = styled.div`
  .share-btn {
    max-width: 20px;
    max-height: 15px;
    mask: url(${shareIcon});
    mask-size: contain;
    mask-repeat: no-repeat;
    display: inline-block;

    margin-top: -7px;
    margin-bottom: 5px;
    background: ${(props) => (props.theme === 'light' ? 'var(--black-pearl)' : 'var(--off-white)')};
    :hover {
      background: var(--hover-color);
    }
  }
`;
