import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    loading: true,
    recommendation: null
};

const recommendationSlice = createSlice({
    name: 'recommendation',
    initialState,
    reducers: {
        getRecommendation(state, action) {
            state.loading = true;
            state.recommendation = null;
        },
        getAllRecommendation(state, action) {
            state.loading = true;
            state.recommendation = null;
        },
        onSuccess(state, action) {
            state.loading = false;
            state.recommendation = action.payload;
        },
        onFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const {
    getRecommendation,
    getAllRecommendation,
    onSuccess,
    onFailure
} = recommendationSlice.actions;
export default recommendationSlice.reducer;
