import axios from 'axios';
import { API_URL } from './api-url.config';
// ;

const title = window.platform_info.title;
export const API = axios.create({
  baseURL: API_URL
});

// export function authHeader() {
//   // return authorization header with jwt token
//   let token = JSON.parse(localStorage.getItem(`access_token_r_${title}`));

//   if (token) {
//     return { Authorization: `Bearer ${token}` };
//   } else {
//     return {};
//   }
// }
// .then(() => {
//     if (JSON.parse(localStorage.getItem('reloaded_once'))) {
//       localStorage.setItem('reloaded_once', false);
//     } else {
//       localStorage.setItem('reloaded_once', true);
//       window.location.reload();
//     }
//   });
export const anonimousUser = async () => {
  const platform = window.platform_info.api_key;

  const data = {
    platform: platform
  };

  return new Promise(function (resolve, reject) {
    // do a thing, possibly async, then…

    axios
      .post(`${API_URL}auth/token/temp`, JSON.stringify(data), {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        // console.log('call');
        localStorage.setItem(`access_token_temp_${title}`, JSON.stringify(res.data.token));
        localStorage.setItem(`refresh_token_temp_${title}`, JSON.stringify(res.data.refreshToken));
        localStorage.setItem(`time_temp_${title}`, Date.now() + 172800000);
        resolve(res.data.token);
      });
  });
};

// Add a request interceptor

API.interceptors.request.use(async function (config) {
  // const token = store.getState().session.token;

  let token = JSON.parse(localStorage.getItem(`access_token_r_${title}`));
  let tempToken = JSON.parse(localStorage.getItem(`access_token_temp_${title}`));
  let beforeTimeTemp = JSON.parse(localStorage.getItem(`time_temp_${title}`));

  const currentTime = Date.now();
  
  if ((!tempToken && !token) || (tempToken && currentTime > beforeTimeTemp)) {
    /**
     *  Either there is "no temp token" or "no access token" or "has temp token but expired"
     */  
    const resolvedToken = await anonimousUser();
    config.headers.Authorization = `Bearer ${resolvedToken}`;
    return config;
  } else {
    /**
     * Token present in the local storage
     */ 
    config.headers.Authorization = token ? `Bearer ${token}` : `Bearer ${tempToken}`;
    return config;
  }
});

API.interceptors.response.use(
  (response) => {
    console.log("Inspecting Interceptor response", response);
    return response;
  },
  (error) => {
    return new Promise(async (resolve, reject) => {
      const {
        config,
        response: { status = '' }
      } = error;
      const originalReq = config;
      let token = JSON.parse(localStorage.getItem(`access_token_r_${title}`));
      let tempToken = JSON.parse(localStorage.getItem(`access_token_temp_${title}`));
      let refreshTtoken = JSON.parse(localStorage.getItem(`refresh_token_r_${title}`));
      console.log('status', status);
      console.log('originalReq', originalReq);
      if ((status == 401 || status == 403) && config && !config.__isRetryRequest && tempToken) {
        originalReq._retry = true;
        const resolvedToken = await anonimousUser();
        originalReq.headers['Authorization'] = `Bearer ${resolvedToken}`;
        resolve(resolvedToken);
        return axios(resolvedToken);
      }
      if ((status == 401 || status == 403) && config && !config.__isRetryRequest && token) {
        originalReq._retry = true;

        let res = fetch(`${API_URL}auth/token/refresh`, {
          method: 'POST',
          // mode: 'cors',
          // cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            // 'Device': 'device',
            // Authorization: `Bearer` + localStorage.getItem('access_token_r')
            Authorization: `Bearer ${token}`
          },
          // redirect: 'follow',
          // referrer: 'no-referrer',
          body: JSON.stringify({
            // token: localStorage.getItem('access_token_r')
            token: refreshTtoken

            // refresh_token: localStorage.getItem("refresh_token")
          })
        })
          .then((res) => res.json())
          .then((res) => {
            // this.setSession({ token: res.token, refresh_token: res.refresh });

            localStorage.setItem(`access_token_r_${title}`, JSON.stringify(res.token));
            localStorage.setItem(`refresh_token_r_${title}`, JSON.stringify(res.refreshToken));

            originalReq.headers['Authorization'] = `Bearer ${res.token}`;
            // originalReq.headers['Device'] = "device";

            return axios(originalReq);
          })
          .catch((error) => {
            console.log({ error });
          });

        resolve(res);
      }
      return Promise.reject(error);
    });
  }
);
// export default function setAuthToken(token) {
//   axios.defaults.headers.common['Authorization'] = '';
//   delete axios.defaults.headers.common['Authorization'];

//   if (token) {
//     axios.defaults.headers.common['Authorization'] = `${token}`;
//   }
// }
// let isAlreadyFetchingAccessToken = false
// let subscribers = []

// function onAccessTokenFetched(access_token) {
//   subscribers = subscribers.filter(callback => callback(access_token))
// }

// function addSubscriber(callback) {
//   subscribers.push(callback)
// }

// axios.interceptors.response.use(function (response) {
//   return response
// }, function (error) {
//   const { config, response: { status } } = error
//   const originalRequest = config

//   if (status === 401) {
//     if (!isAlreadyFetchingAccessToken) {
//       isAlreadyFetchingAccessToken = true
//       store.dispatch(fetchAccessToken()).then((access_token) => {
//         isAlreadyFetchingAccessToken = false
//         onAccessTokenFetched(access_token)
//       })
//     }

//     const retryOriginalRequest = new Promise((resolve) => {
//       addSubscriber(access_token => {
//         originalRequest.headers.Authorization = 'Bearer ' + access_token
//         resolve(axios(originalRequest))
//       })
//     })
//     return retryOriginalRequest
//   }
//   return Promise.reject(error)
// })
