import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  items: [],
  searchItem: [],
  contents: [],
  storedTracks: [],
  popularArtist: [],
  popularArtistAll: [],
  loading: true,
  error: null
};
const artistSlice = createSlice({
  name: 'artist',
  initialState,
  reducers: {
    getArtist(state, action) {
      state.loading = true;
    },
    fetchArtistSuccess(state, action) {
      state.error = null;
      state.items = action.payload;
      state.loading = false;
    },
    getContent(state, action) {
      state.loading = true;
    },
    fetchContentSuccess(state, action) {
      state.error = null;
      state.loading = false;
      state.contents = action.payload;
      state.storedTracks = [...state.storedTracks, ...action.payload.items];
    },
    topArtist(state, action) {
      state.loading = true;
    },
    topArtistSuccess(state, action) {
      state.loading = false;
      state.popularArtist = action.payload;
    },
    topArtistAll(state, action) {
      state.loading = true;
    },
    topArtistAllSuccess(state, action) {
      state.loading = false;
      state.popularArtistAll = action.payload;
    },
    onFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getArtistSearch(state, action) {
      state.loading = true;
    },
    getArtistSearchSuccess(state, action) {
      state.loading = false;

      state.searchItem = action.payload;
    },
    getArtistSearchFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});
export const {
  getArtist,
  fetchArtistSuccess,
  getContent,
  fetchContentSuccess,
  topArtist,
  topArtistSuccess,
  topArtistAll,
  topArtistAllSuccess,
  onFailure,
  getArtistSearch,
  getArtistSearchSuccess,
  getArtistSearchFailure
} = artistSlice.actions;
export default artistSlice.reducer;
