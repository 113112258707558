import styled from 'styled-components';

export const LoaderCircle = styled.div`
  margin: 0 auto;
  margin-top: 10px;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid var(--button-bg);
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoaderCircleSmall = styled.div`
  margin: 0 auto;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid var(--button-bg);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Title = styled.h3`
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
`;
