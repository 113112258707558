import { API } from 'utils/api.config';
export async function getCategory(params) {
  return await API.get(`api/category`, {
    params
  });
}
export async function getContentByCategoryId(payload) {
  return await API.get(`api/v2/content/seemore/${payload.id}?page=${payload.page}`);
}
export async function getContentByCategorySlug(payload) {
  return await API.get(`api/v2/content/seemore/slug/${payload.slug}?page=${payload.page}`);
}
