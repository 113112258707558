import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  items: [],
  modalData: {},
  data: null,
  loading: true,
  error: null
};
const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    getSubscription(state, action) {
      state.loading = true;
    },
    onGetSubscriptionSuccess(state, action) {
      state.items = action.payload;
      state.loading = false;
    },
    onGetSubscriptionFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    onPostSubscribe(state, action) {
      state.loading = true;
      state.modalData = action.payload;
    },
    onPostSubscribeSuccess(state, action) {
      state.modalData = action.payload;
      state.loading = false;
    },
    onPostSubscribeFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    onUnsubscribe(state, action) {
      state.loading = true;
      state.data = action.payload;
    },
    onUnsubscribeSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
    },
    onUnsubscribeFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});
export const {
  getSubscription,
  onGetSubscriptionSuccess,
  onGetSubscriptionFailure,
  onPostSubscribe,
  onPostSubscribeSuccess,
  onPostSubscribeFailure,
  onUnsubscribe,
  onUnsubscribeSuccess,
  onUnsubscribeFailure
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
