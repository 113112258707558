import { takeLatest, put, all, call } from 'redux-saga/effects';
import { onSuccess, onFailure, getTracks, getContent, fetchContentSuccess, getTracksSlug } from './audio.slice';
import { getContentById, getAlbumById, getArtistAudioById, getArtistAudioBySlug, getAlbumBySlug, getAudioBySlug } from './audio.utils';
export function* fetchTracks({ payload: { id, type } }) {
  try {
    if (type === 'album') {
      const { data } = yield call(getAlbumById, id);
      yield put(onSuccess({ data, type }));
    }
    if (type === 'audio') {
      const { data } = yield call(getContentById, id);
      yield put(onSuccess({ data, type }));
    }
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}
export function* fetchTracksSlug({ payload: { slug, type } }) {
  try {
    if (type === 'album') {
      const { data } = yield call(getAlbumBySlug, slug);
      yield put(onSuccess({ data, type }));
    }
    if (type === 'audio') {
      const { data } = yield call(getAudioBySlug, slug);
      yield put(onSuccess({ data, type }));
    }
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}
export function* fetchArtistContent({ payload: { slug, page, type } }) {
  try {
    const { data } = yield call(getArtistAudioBySlug, slug, page, type);

    yield put(fetchContentSuccess(data));
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}
export function* onFetchStart() {
  yield takeLatest(getTracks.toString(), fetchTracks);
}
export function* onFetchSlugStart() {
  yield takeLatest(getTracksSlug.toString(), fetchTracksSlug);
}
export function* onfetchArtistContent() {
  yield takeLatest(getContent.toString(), fetchArtistContent);
}

export function* audioSagas() {
  yield all([
    call(onFetchStart), 
    call(onFetchSlugStart), 
    call(onfetchArtistContent)
  ]);
}
