import React from 'react';

const placeholderLogoWhite = window.platform_info.placeholderWhite;
const placeholderLogoblack = window.platform_info.placeholderDark;
const types = ['audio', 'album'];

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Img from 'react-image';

export function Placeholder({ type, orientationType = 'horizotal' }) {
  const { theme } = useSelector((state) => state.theme);

  if (theme === 'light') {
    if (orientationType === 'vertical') {
      const image = (
        <PlaceholderImageVetical theme={theme}>
          <Img src={placeholderLogoWhite} alt="Placeholder" />
        </PlaceholderImageVetical>
      );
      return image;
    } else {
      const image = !types.includes(type) ? (
        <PlaceholderImage theme={theme}>
          <Img src={placeholderLogoWhite} alt="Placeholder" />
        </PlaceholderImage>
      ) : (
        <PlaceholderImageAlbum theme={theme}>
          <Img src={placeholderLogoWhite} alt="Placeholder" />
        </PlaceholderImageAlbum>
      );
      return image;
    }
  } else {
    if (orientationType === 'vertical') {
      const image = (
        <PlaceholderImageVetical theme={theme}>
          <Img src={placeholderLogoblack} alt="Placeholder" />
        </PlaceholderImageVetical>
      );
      return image;
    } else {
      const image = !types.includes(type) ? (
        <PlaceholderImage theme={theme}>
          <Img src={placeholderLogoblack} alt="Placeholder" />
        </PlaceholderImage>
      ) : (
        <PlaceholderImageAlbum theme={theme}>
          <Img src={placeholderLogoblack} alt="Placeholder" />
        </PlaceholderImageAlbum>
      );

      return image;
    }
  }
}
const PlaceholderImage = styled.section`
  width: 100%;
  height: 100%;
  border: 1px solid #333;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7.37% 0;
  background: ${(props) => (props.theme === 'light' ? 'white' : 'var(--background-primary)')};
  img {
    width: 38%;
  }
`;
const PlaceholderImageAlbum = styled(PlaceholderImage)`
  padding: 17.37% 0;
  img {
    width: 60%;
  }
`;
const PlaceholderImageVetical = styled(PlaceholderImage)`
  padding: 42.1% 0;
  img {
    width: 60%;
  }
`;
