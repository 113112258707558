import { createSlice } from '@reduxjs/toolkit';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
// import { toast } from 'react-toastify';
import { isSameTrackAndPlaying, isSameTrack } from 'utils/player';
import { openPlayer } from 'redux/video/video.slice';
const initialState = {
  // test
  volume: 70,
  isInShuffleMode: false,
  isInRepeatMode: false,
  contents: [],
  banner: null,
  tracks: null,
  storedTracks: [], // maybe need
  activeTrackId: null,
  isPlaying: false,
  playlist: [],
  error: null,
  loading: true,
  playedTime: null
};

const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setPlayedTime(state, action) {
      state.playedTime = action.payload;
    },
    getTracks(state) {
      state.loading = true;
      state.tracks = null;
      state.banner = null;
    },
    getTracksSlug(state) {
      state.loading = true;
      state.tracks = null;
      state.banner = null;
    },
    onSuccess(state, { payload: { data, type } }) {
      const banner = type === 'album' ? data.albumInfo : data;
      const tracks = type === 'album' ? data.contents : [data];
      state.banner = banner;
      state.tracks = tracks;
      state.storedTracks = [...state.storedTracks, ...tracks];
      state.loading = false;
    },
    getContent(state, action) {
      state.loading = true;
    },
    fetchContentSuccess(state, action) {
      state.loading = false;
      state.contents = action.payload;
      state.storedTracks = [...state.storedTracks, ...action.payload.items];
    },

    onFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    setActiveTrack(state, { payload }) {
      state.activeTrackId = payload;
    },
    resetActiveTrack(state) {
      state.activeTrackId = null;
    },
    setIsPlaying(state, action) {
      state.isPlaying = action.payload;
    },
    setTrackInPlaylist(state, { payload: trackId }) {
      if (state.playlist.indexOf(trackId) !== -1) {
        return;
      } else {
        state.playlist.push(trackId);
      }
    },
    removeTrackFromPlaylist(state, { payload: trackId }) {
      const index = state.playlist.indexOf(trackId);
      const playlist = [...state.playlist.slice(0, index), ...state.playlist.slice(index + 1)];
      state.playlist = playlist;
    },
    emptyPlaylist(state) {
      state.playlist = [];
    },
    setShuffleMode(state) {
      state.isInShuffleMode = !state.isInShuffleMode;
    },

    setRepeatMode(state) {
      state.isInRepeatMode = !state.isInRepeatMode;
    },
    // need to fix
    closePlayer(state) {
      state.isPlaying = false;
      state.activeTrackId = null;
    }
  },
  extraReducers: {
    [openPlayer]: (state, action) => {
      if (state.isPlaying) {
        state.isPlaying = false;
      }
    }
  }
});
export const {
  getTracks,
  getTracksSlug,
  getContent,
  fetchContentSuccess,
  onSuccess,
  onFailure,
  closePlayer,
  playPause,
  setIsPlaying,
  setActiveTrack,
  setTrackInPlaylist,
  setShuffleMode,
  setRepeatMode,
  setPlayedTime
} = audioSlice.actions;

export default audioSlice.reducer;

// thunk start here
function isInPlaylist(playlist, trackId) {
  console.log(playlist, trackId);
  return find(playlist, isSameTrack(trackId));
}

export const activateTrack = (trackId) => (dispatch, getState) => {
  const playlist = getState().audio.playlist;
  const previousActiveTrackId = getState().audio.activeTrackId;
  const isCurrentlyPlaying = getState().audio.isPlaying;
  const isPlaying = !isSameTrackAndPlaying(previousActiveTrackId, trackId, isCurrentlyPlaying);

  dispatch(setIsPlaying(isPlaying));
  dispatch(setActiveTrack(trackId));

  if (!isInPlaylist(playlist, trackId)) {
    dispatch(setTrackInPlaylist(trackId));
  }
};

export const addTrackToPlaylist = (trackId) => (dispatch, getState) => {
  const playlist = getState().audio.playlist;

  if (!isInPlaylist(playlist, trackId)) {
    dispatch(setTrackInPlaylist(trackId));
  }

  if (!playlist.length) {
    dispatch(activateTrack(trackId));
  }
};
export const addAllTrackToPlaylist = (trackIds) => (dispatch, getState) => {
  const playlist = getState().audio.playlist;
  trackIds.forEach((trackId) => {
    const previousActiveTrackId = getState().audio.activeTrackId;
    const isCurrentlyPlaying = getState().audio.isPlaying;
    const isPlaying = !isSameTrackAndPlaying(previousActiveTrackId, trackId, isCurrentlyPlaying);

    dispatch(setIsPlaying(isPlaying));
    dispatch(setActiveTrack(trackId));
    dispatch(setTrackInPlaylist(trackId));
  });
  if (!playlist.length) {
    dispatch(activateTrack(trackIds[0]));
  }
};

function getIteratedTrack(playlist, currentActiveTrackId, iterate) {
  const index = findIndex(playlist, isSameTrack(currentActiveTrackId));
  const nextIndex = (index + iterate) % playlist.length;
  return playlist[nextIndex];
}

function getRandomTrack(playlist, currentActiveTrackId) {
  const index = findIndex(playlist, isSameTrack(currentActiveTrackId));

  function getRandomIndex() {
    const randNum = Math.floor(Math.random() * playlist.length);
    if (randNum === index && playlist.length > 1) {
      return getRandomIndex();
    } else {
      return randNum;
    }
  }
  return playlist[getRandomIndex()];
}

export const activateIteratedPlaylistTrack = (currentActiveTrackId, iterate) => (
  dispatch,
  getState
) => {
  const playlist = getState().audio.playlist;
  const nextActiveTrackId = getIteratedTrack(playlist, currentActiveTrackId, iterate);
  const isInShuffleMode = getState().audio.isInShuffleMode;

  if (nextActiveTrackId && isInShuffleMode === false) {
    dispatch(activateTrack(nextActiveTrackId));
  } else if (isInShuffleMode) {
    dispatchRandomTrack(playlist, currentActiveTrackId, dispatch);
  }
};

function dispatchRandomTrack(playlist, currentActiveTrackId, dispatch) {
  const randomActiveTrackId = getRandomTrack(playlist, currentActiveTrackId);
  dispatch(activateTrack(randomActiveTrackId));
}

export const activateIteratedStreamTrack = (currentActiveTrackId, iterate) => (
  dispatch,
  getState
) => {
  const isInShuffleMode = getState().audio.isInShuffleMode;
  const playlist = getState().audio.playlist;

  const streamList = getStreamList(getState);

  if (isInShuffleMode) {
    dispatchRandomTrack(streamList, currentActiveTrackId, dispatch);
  } else {
    const nextStreamTrackId = findNextStreamTrackId(
      streamList,
      playlist,
      currentActiveTrackId,
      iterate
    );

    if (nextStreamTrackId) {
      dispatch(activateTrack(nextStreamTrackId));
    } else {
      dispatch(setIsPlaying(false));
    }
  }
};

function getStreamList(getState) {
  // const storedTracks = getState().audio.storedTracks;
  // if (storedTracks) {
  //   return storedTracks;
  // }
  return getState().audio.tracks.map((item) => item.id);
}

function findNextStreamTrackId(streamList, playlist, currentActiveTrackId, iterate) {
  let nextStreamTrackId = getIteratedTrack(streamList, currentActiveTrackId, iterate);
  // while (playlist.includes(nextStreamTrackId)) {
  //   nextStreamTrackId = getIteratedTrack(
  //     streamList,
  //     nextStreamTrackId,
  //     iterate
  //   );
  // }
  return nextStreamTrackId;
}

export const removeTrackFromPlaylist = (track) => (dispatch, getState) => {
  const activeTrackId = getState().audio.activeTrackId;
  const isPlaying = getState().audio.isPlaying;
  const isRelevantTrack = isSameTrackAndPlaying(activeTrackId, track.id, isPlaying);

  if (isRelevantTrack) {
    dispatch(activateIteratedPlaylistTrack(activeTrackId, 1));
  }

  const playlistSize = getState().audio.playlist.length;
  if (playlistSize < 2) {
    //   dispatch(deactivateTrack());
    dispatch(setIsPlaying(false));
    //   dispatch(resetToggle(toggleTypes.PLAYLIST));
    //   dispatch(resetToggle(toggleTypes.VOLUME));
  }

  dispatch(removeTrackFromPlaylist(track.id));
};
