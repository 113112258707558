import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import App from 'App';
import { checkUserSession } from 'redux/user/user.actions';
import * as serviceWorker from 'serviceWorker';
import './i18n';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from 'react-mixpanel';
// const renderApp = preloadedState => {
// const store = configureStore(preloadedState);
document.title = window.platform_info.title;

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback="">
        <MixpanelProvider mixpanel={mixpanel}>
          <App />
        </MixpanelProvider>
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
store.dispatch(checkUserSession());
// };
// (async () => renderApp(await store.dispatch(checkUserSession())))();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
