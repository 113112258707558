import { API } from 'utils/api.config';

export async function getChannelsApi() {
  return await API.get(`api/channel`);
}
export async function getChannelsSeeMoreApi() {
  return await API.get(`api/channel/seemore`);
}
export async function getSingleChannel(id) {
  return await API.get(`/api/channel/view/${id}`);
}
