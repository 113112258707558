import React, { useState } from 'react';
import leftIcon from '../../assets/images/up-arrow.png';
import playIcon from '../../assets/images/play-icon.png';
import { Switch, Route, Link, useRouteMatch, useLocation } from 'react-router-dom';
import { BiPlay, BiPause } from 'react-icons/bi';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isNumeric } from 'utils/numberCheck';

const Episode = ({
  title,
  horizontalThumbnails,
  episodeIndex,
  seriesId,
  seasonIndex,
  currentTrack,
  paramSeriesIndex,
  paramEpisodeIndex,
  setParamSeriesIndex,
  setParamEpisodeIndex,
  from
}) => {
  const { theme } = useSelector((state) => state.theme);
  const search = useLocation().search;
  const seasonParam = new URLSearchParams(search).get('season_index') - 1;
  const episodeParam = new URLSearchParams(search).get('episode_index') - 1;

  console.log("Logging Param Series Index", paramSeriesIndex)
  console.log("Logging Param Episode Index", paramEpisodeIndex)
  console.log("Logging SeriesId", seriesId);
  console.log("Logging seasonIndex", seasonIndex);
  console.log("Logging episodeIndex", episodeIndex);

  const handleEpisodeChange = () => {
    setParamSeriesIndex(seasonIndex);
    setParamEpisodeIndex(episodeIndex);
  }

  // console.log("Series Id printing", isNumeric(seriesId));
  const episodePath = `/series/${seriesId}?season_index=${seasonIndex + 1}&episode_index=${episodeIndex + 1}`;


  if (!from) return (
    <EpisodeWrapper
      theme={theme}
      episodeParam={episodeParam}
      seasonParam={seasonParam}
      episodeIndex={episodeIndex}
      seasonIndex={seasonIndex}
    >
      <Link
        className="single-episode"
        key={episodeIndex}
        to={episodePath}
      >
        <span>
          <div className="img-container">
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}100xauto/${horizontalThumbnails[0]?.path}`}
              alt="Episode Banner"
            />
          </div>
          <div className="content">
            <p>{title}</p>
          </div>
        </span>

        <div className="video-icon">
          <button>
            {paramSeriesIndex === seasonIndex && paramEpisodeIndex === episodeIndex
              ? currentTrack?.premium
                ? <BiPlay />
                : <BiPause />
              : <BiPlay />}
          </button>
        </div>
      </Link>
    </EpisodeWrapper>
  )

  return (
    <EpisodeWrapper
      theme={theme}
      episodeParam={paramEpisodeIndex}
      seasonParam={paramSeriesIndex}
      episodeIndex={episodeIndex}
      seasonIndex={seasonIndex}
    >
      <div
        className="single-episode"
        key={episodeIndex}
        onClick={handleEpisodeChange}
      >
        <span>
          <div className="img-container">
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}100xauto/${horizontalThumbnails[0]?.path}`}
              alt="Episode Banner"
            />
          </div>
          <div className="content">
            <p>{title}</p>
          </div>
        </span>

        <div className="video-icon">
          <button>
            {paramSeriesIndex === seasonIndex && paramEpisodeIndex === episodeIndex
              ? currentTrack?.premium
                ? <BiPlay />
                : <BiPause />
              : <BiPlay />}
          </button>
        </div>
      </div>
    </EpisodeWrapper>
  );
}

const Season = ({
  horizontalThumbnails,
  title,
  episodeCount,
  contents,
  index: indexSeason,
  onClickPlay,
  currentTrack,
  seriesIndex,
  episodeIndex,
  setIndex,
  setSeriesIndex,
  id: seriesId,
  from
}) => {
  const [show, setShow] = useState(true);
  let { path, url } = useRouteMatch();
  const handlePlayClick = () => {
    if (currentTrack?.premium && !userProfiles?.subscribe) {
      history.push('/subscription');
    } else {
      onClickPlay();
    }
  };
  // const handleClick = (item, i) => {
  //   handlePlayClick();
  //   setIndex(i);
  //   setSeriesIndex(indexSeason);
  // };

  return (
    <span key={indexSeason}>
      <div className="heading" key={indexSeason}>
        <div className="img-container">
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}100xauto/${horizontalThumbnails[0]?.path}`}
            alt="Season Banner"
          />
        </div>
        <div className="content">
          <h4>{title} </h4>
          <h4>
            Season {indexSeason + 1} | {episodeCount} episode{' '}
          </h4>
        </div>

        <div className="icon">
          <button onClick={() => setShow(!show)}>
            <img src={leftIcon} className={show ? '' : 'open'} alt="Icon" />
          </button>
        </div>
      </div>
      <div className={show ? 'episodes padding' : 'episodes'}>
        {show &&
          contents &&
          contents.map((item, index) => {
            const { title, horizontalThumbnails, id } = item;
            return (
              <Episode
                title={title}
                horizontalThumbnails={horizontalThumbnails}
                episodeIndex={index}
                seriesId={seriesId}
                seasonIndex={indexSeason}
                currentTrack={currentTrack}
                paramSeriesIndex={seriesIndex}
                paramEpisodeIndex={episodeIndex}
                setParamSeriesIndex={setSeriesIndex}
                setParamEpisodeIndex={setIndex}
                from={from}
              />
            )
          })}
      </div>
    </span>
  );
};

export default Season;

const EpisodeWrapper = styled.span`
.single-episode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.6rem;
  border: ${({
  episodeParam,
  seasonParam,
  episodeIndex,
  seasonIndex
}) => (seasonParam === seasonIndex && episodeParam === episodeIndex ? '2px solid #E50019' : '1px solid #fff')};
  margin-bottom: 1rem;
  padding: 0.2rem 0;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  position: relative;

  /* :after {
    content: '';
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: 0.3s ease;
  } */
  :hover {
    /* transform: scale(1.05); */
    background-color: ${(props) => (props.theme === 'light' ? '#ffff' : '#262626')};
    /* background-color: #262626; */
  }
  span {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .img-container {
    height: 60px;
    width: 110px;
    min-width: 110px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  p {
    margin-bottom: 0.3rem;
    font-size: 14px;
    /* color: #fafafa !important; */
  }
  :last-child {
    margin-bottom: 0rem;
  }
}
`;
