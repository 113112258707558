import { Button } from 'components/Button';
import React from 'react';
import styled from 'styled-components';
import crown from '../../assets/images/crown-large.png';
const PremiumOverlay = ({ handlePlayClick }) => {
  return (
    <Wrapper className="play-icon">
      <Image>
        <img src={crown} alt="Premium Icon" />
      </Image>
      <p>Please subscribe to watch full video</p>
      <Buttons>
        <Button variant="primary" className="premium-btn" onClick={handlePlayClick}>
          SUBSCRIBE
        </Button>
      </Buttons>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  p {
    color: #fafafa !important;
    text-align: center;
  }
`;
const Image = styled.div`
  display: flex;
  justify-content: center;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
`;
export default PremiumOverlay;
