import { takeLatest, takeEvery, put, all, call } from 'redux-saga/effects';
import {
    fetchWishlistsSuccess,
    fetchWishlistsFailure,
    displayWishlists,
    createWishlist,
    createWishlistSuccess,
    createWishlistFailure,
    getSelectedWishlist,
    getSelectedWishlistSuccess,
    getSelectedWishlistFailure,
    addItemToWishlist,
    addItemToWishlistSuccess,
    addItemToWishlistFailure
} from './wishlist.slice';
import { getWishlists, postWishlist, getWishlistContentById, addItem } from './wishlist.utils';
import { toast } from 'react-toastify';
export function* saveWishlist({ payload: { contentId, title } }) {
    try {
        const { data } = yield call(postWishlist, contentId, title);
        toast('Wishlist Created !', {
            position: toast.POSITION.TOP_RIGHT
        });
        yield put(createWishlistSuccess(data));
    } catch (error) {
        yield put(createWishlistFailure(error.toString()));
    }
}
export function* fetchWishlists({ payload }) {
    try {
        const { data } = yield call(getWishlists, payload);
        yield put(fetchWishlistsSuccess({ data, type: payload }));
    } catch (error) {
        yield put(fetchWishlistsFailure(error.toString()));
    }
}
export function* fetchWishlist({ payload }) {
    try {
        const { data } = yield call(getWishlistContentById, payload);
        yield put(getSelectedWishlistSuccess(data));
    } catch (error) {
        yield put(getSelectedWishlistFailure(error.toString()));
    }
}
export function* postAddItem({ payload }) {
    try {
        const { data } = yield call(addItem, payload);
        toast('Added to wishlist!', {
            position: toast.POSITION.TOP_RIGHT
        });
        yield put(addItemToWishlistSuccess(data));
    } catch (error) {
        yield put(addItemToWishlistFailure(error.toString()));
    }
}
export function* onCreateWishlists() {
    yield takeLatest(createWishlist.toString(), saveWishlist);
}
export function* onFetchWishlists() {
    yield takeEvery(displayWishlists.toString(), fetchWishlists);
}
export function* onFetchWishlist() {
    yield takeLatest(getSelectedWishlist.toString(), fetchWishlist);
}
export function* onAddToWishlist() {
    yield takeLatest(addItemToWishlist.toString(), postAddItem);
}
export function* wishlistSagas() {
    yield all([
        call(onFetchWishlists),
        call(onCreateWishlists),
        call(onFetchWishlist),
        call(onAddToWishlist)
    ]);
}
