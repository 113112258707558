import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getAuxiliaryPage, fetchAuxiliarySuccess, onFailure } from './auxiliary.slice';
import { getPage } from './auxiliary.utils';

export function* fetchAuxiliary({ payload }) {
  try {
    const { data } = yield call(getPage, payload);

    yield put(fetchAuxiliarySuccess(data));
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}

export function* onFetchAuxiliary() {
  yield takeLatest(getAuxiliaryPage.toString(), fetchAuxiliary);
}

export function* auxiliarySagas() {
  yield all([call(onFetchAuxiliary)]);
}
