import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import firebase from 'utils/firebase';
import { getUserProfileDocumentv2 } from 'redux/user/user.utils';
import { setUserSub } from 'redux/userSubInfo/userSub.slice';
import { API_URL } from 'utils/api-url.config';
import jwtDecode from 'jwt-decode';

export default function useAnanymousCheck() {
  const location = useLocation();
  const { currentUser } = useSelector((state) => state.user);
  // const profileData = JSON.parse(localStorage.getItem("profile_data"));
  const dispatch = useDispatch();
  const title = window?.platform_info.title;
  const token = localStorage.getItem(`access_token_r_${title}`);
  const couponTrue = localStorage.getItem(`coupon_login_${title}`);
  const refreshToken =
    !couponTrue && JSON.parse(localStorage.getItem(`refresh_token_r_${title}`))
      ? JSON.parse(localStorage.getItem(`refresh_token_r_${title}`))
      : '';

  const decodedAccessToken = token && jwtDecode(token);
  // console.log(decodedAccessToken, couponTrue);

  // async function fetchData() {
  //     const data = await getUserProfileDocumentv2();
  //     localStorage.setItem("profile_data", JSON.stringify(data));
  //     return Promise.resolve(data.id);
  // }

  function makeRefreshAPICall() {
    fetch(`${API_URL}auth/token/refresh`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        token: refreshToken
      })
    })
      .then((res) => res.json())
      .then((res) => {
        const user = jwtDecode(res?.token);
        // console.log("Refresh token and set to localstorage", user)
        localStorage.setItem(`access_token_r_${title}`, JSON.stringify(res?.token));
        localStorage.setItem(`refresh_token_r_${title}`, JSON.stringify(res?.refreshToken));
        dispatch(setUserSub(user));
        console.log('Letting know the application new subscription status');
      })
      .catch((error) => {
        console.log({ error });
      });
  }

  function observeDB(id) {
    console.log('Trying to observe subscriptions db');
    const subscriptionsRef = firebase.database().ref('subscriptions').child(id);
    subscriptionsRef.on('value', (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        // console.log("Entered in useAnanymous check");
        // const decodedToken = jwtDecode(token)

        if (
          (decodedAccessToken?.subscribe === false && data.data.subscribe === true) ||
          (decodedAccessToken?.subscribe === true && data.data.subscribe === false)
        ) {
          // console.log("Making refresh call 1");
          if (refreshToken) {
            makeRefreshAPICall();
          }
        }
        // else {
        // Token decode kore sobai ke janaben
        // console.log("Token decode kore sobai ke janaben 1")
        // console.log(decodedToken)
        // dispatch(setUserSub(decodedToken))
        // }
      } else {
        console.log('No SVOD data available');
      }
    });

    // Observe tvod_subscription db
    const tvodRef = firebase.database().ref('tvod_subscription').child(id);
    tvodRef.on('value', (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();

        if (
          (decodedAccessToken?.isTVOD === false && data.data.tvod === true) ||
          (decodedAccessToken?.isTVOD === true && data.data.tvod === false)
        ) {
          if (refreshToken) {
            makeRefreshAPICall();
          }
        }
      } else {
        console.log('No TVOD data available');
      }
    });
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        // console.log("Auth state observer", user)

        if (currentUser) {
          if (!couponTrue && decodedAccessToken?.id) {
            observeDB(decodedAccessToken?.id);
          }
        }

        // if (profileData?.id) {
        //observe firebase
        // observeDB(profileData?.id)
        // } else {
        // fetchData().then(res => {
        //     observeDB(res);
        // })
        // }
      } else {
        // User is signed out
        // ...
        // console.log("User signed out")
        firebase
          .auth()
          .signInAnonymously()
          .then((res) => {
            // Signed in..
            // console.log("Ananymous check signed in user", res)
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ...
            console.log('Error anonymous sign in');
            if (errorCode === 'auth/operation-not-allowed') {
              console.log('You must enable Anonymous auth in the Firebase Console.');
            }
          });
      }
    });
  }, [location, currentUser]);
}
