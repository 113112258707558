import firebase, { auth } from './firebase';

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged((userAuth) => {
            unsubscribe();
            resolve(userAuth);
        }, reject);
    });
};
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();

googleProvider.setCustomParameters({ prompt: 'select_account' });
facebookProvider.setCustomParameters({
    display: 'popup'
}); // export const singInWithGoogle = () => auth.signInWithPopup(googleProvider);
// export const singInWithFacebook = () => auth.signInWithPopup(facebookProvider);
export const signOut = () => auth.signOut();
export default auth;

// if (method === 'signInWithPhoneNumber') {
//   // Modify confirm method to include profile creation
//   return {
//     ...userData,
//     confirm: code =>
//       // Call original confirm
//       userData.confirm(code).then(({ user, additionalUserInfo }) =>
//         createUserProfile(dispatch, firebase, user, {
//           phoneNumber: user.providerData[0].phoneNumber,
//           providerData: user.providerData
//         }).then(profile => ({ profile, user, additionalUserInfo }))
//       )
//   }
// }

// export function signInWithPhoneNumber(
//   firebase,
//   dispatch,
//   phoneNumber,
//   applicationVerifier,
//   options = {}
// ) {
//   return login(dispatch, firebase, {
//     phoneNumber,
//     applicationVerifier,
//     ...options
//   })
// }
