import React, {useEffect, useState} from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import jwt from 'jwt-decode';
import { signInSuccess } from 'redux/user/user.actions';
import { useSelector, useDispatch } from 'react-redux';
import { subscribeHandler } from 'utils/payment.utils';

export default function SubscribeRedirect() {
    const history = useHistory();
    const { currentUser } = useSelector((state) => state.user);
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search)
    const deviceType = searchParams.get('deviceType');
    const token = searchParams.get('token');
    const title = window.platform_info.title;
    const [customMessage, setCustomMessage] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(token);
        console.log(deviceType);
        if (deviceType) {
            if (!currentUser && token) {
                console.log("Entered here", token)
                const user = jwt(token);
                if (user['id'] !== undefined) {
                    dispatch(signInSuccess(user));
                    localStorage.setItem(`ios_sub_user_${title}`, JSON.stringify(user));
                    localStorage.setItem(`access_token_r_${title}`, JSON.stringify(token));
                    localStorage.setItem(`refresh_token_r_${title}`, JSON.stringify(token));
                    localStorage.removeItem(`access_token_temp_${title}`);
                    localStorage.removeItem(`refresh_token_temp_${title}`);
                    subscribeHandler();
                }
            }
            if (!currentUser && !token) {
                setCustomMessage("Access Denied")
            }
        }
    }, [])

    console.log(customMessage);

    return <></>;
}
