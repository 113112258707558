import { Mixpanel } from './Mixpanel';

export const mixPanelAnalytics = {
  trackBanner,
  trackSubscription,
  trackWebScreen,
  trackSeeMore,
  trackArtist,
  trackLogin,
  trackFeatureContent,
  trackFeedContent,
  trackSearchTitle,
  trackSearchContent,
  trackTrailerContent,
  trackFreeContent,
  trackPremiumContent
};

function trackBanner(param) {
  Mixpanel.track('web_banner_content', {
    web_banner_title: param
  });
}
function trackSubscription(param) {
  Mixpanel.track('web_subscription_type', {
    web_subscription_type: param
  });
}
function trackWebScreen(param) {
  Mixpanel.track('web_screen_name', {
    web_screen_name: param
  });
}
function trackSeeMore(param) {
  Mixpanel.track('web_seemore_title', {
    web_seemore_title: param
  });
}

function trackArtist(param) {
  Mixpanel.track('web_artist_title', {
    web_artist_title: param
  });
}
function trackLogin(param) {
  Mixpanel.track('web_login_type', {
    web_login_type: param
  });
}
function trackFeatureContent(param) {
  Mixpanel.track('web_feature_content', {
    web_feature_content: param
  });
}
function trackFeedContent(param) {
  Mixpanel.track('web_feed_content', {
    web_feed_content: param
  });
}
function trackSearchTitle(param) {
  Mixpanel.track('web_search_title', {
    web_search_title: param
  });
}
function trackSearchContent(param) {
  Mixpanel.track('web_search_content', {
    web_search_content: param
  });
}
function trackTrailerContent(param) {
  Mixpanel.track('web_trailer_title', {
    web_trailer_title: param
  });
}
function trackFreeContent(param) {
  Mixpanel.track('web_free_content', {
    web_free_content: param
  });
}
function trackPremiumContent(param) {
  Mixpanel.track('web_premium_content', {
    web_premium_content: param
  });
}
