import createSagaMiddleware from 'redux-saga';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootSaga from './sagas';
// Import all the reducer here
import favouriteReducer from './favourite/favourite.slice';
import themeReducer from './theme/theme.slice';
import userSubReducer from './userSubInfo/userSub.slice';
import auxiliaryReducer from './auxilary/auxiliary.slice';
import paymentReducer from './payment/payment.slice';
import artistReducer from './artist/artist.slice';
import userReducer from './user/user.reducer';
import bannerReducer from './banner/banner.slice';
import categoryReducer from './category/category.reducer';
import audioReducer from './audio/audio.slice';
import videoReducer from './video/video.slice';
import recommendationReducer from './recommendation/recommendation.slice';
import wishlistReducer from './wishlist/wishlist.slice';
import modalReducer from './modal/modal.slice';
import searchReducer from './search/search.slice';
import channelReducer from './channel/channel.slice';
import subscriptionReducer from './subscription/subscription.slice';
import feedReducer from './feed/feed.slice';
const sagaMiddleware = createSagaMiddleware();

const middleware = [...getDefaultMiddleware(), sagaMiddleware];
const rootReducer = {
  feed: feedReducer,
  favourite: favouriteReducer,
  theme: themeReducer,
  userSub: userSubReducer,
  auxiliary: auxiliaryReducer,
  payment: paymentReducer,
  artist: artistReducer,
  user: userReducer,
  banner: bannerReducer,
  category: categoryReducer,
  audio: audioReducer,
  video: videoReducer,
  recommendation: recommendationReducer,
  wishlist: wishlistReducer,
  modal: modalReducer,
  search: searchReducer,
  channel: channelReducer,
  subscription: subscriptionReducer
};
const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production'
});

store.sagaTask = sagaMiddleware.run(rootSaga);

export default store;
