import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
    fetchContentSuccess,
    onFailure,
    getContent,
    getSeries,
    fetchSeriesSuccess,
    getCatchup,
    fetchCatchupSuccess,
    getContentSlug,
    fetchContentBySlugSuccess,
    getSeriesSlug,
    fetchSeriesBySlugSuccess
} from './video.slice';
import { getContentById, getSeriesById, getCatchupById, getContentBySlug, getSeriesBySlug } from './video.utils';
export function* fetchContent({ payload }) {
    try {
        const { data } = yield call(getContentById, payload);
        yield put(fetchContentSuccess(data));
    } catch (error) {
        yield put(onFailure(error.toString()));
    }
}

export function* fetchSeries({ payload }) {
    try {
        const { data } = yield call(getSeriesById, payload);
        yield put(fetchSeriesSuccess(data));
    } catch (error) {
        yield put(onFailure(error.toString()));
    }
}

export function* fetchCatchup({ payload }) {
    try {
        const { data } = yield call(getCatchupById, payload);
        yield put(fetchCatchupSuccess(data));
    } catch (error) {
        yield put(onFailure(error.toString()));
    }
}

export function* fetchContentSlug({ payload }) {
    try {
        const { data } = yield call(getContentBySlug, payload);
        yield put(fetchContentBySlugSuccess(data));
    } catch (error) {
        yield put(onFailure(error.toString()));
    }
}

export function* fetchSeriesSlug({ payload }) {
    try {
        const { data } = yield call(getSeriesBySlug, payload);
        yield put(fetchSeriesBySlugSuccess(data));
    } catch (error) {
        yield put(onFailure(error.toString()));
    }
}

export function* onFetchContentStart() {
    yield takeLatest(getContent.toString(), fetchContent);
}

export function* onFetchSeriesRequest() {
    yield takeLatest(getSeries.toString(), fetchSeries);
}

export function* onFetchCatchupRequest() {
    yield takeLatest(getCatchup.toString(), fetchCatchup);
}

export function* onFetchContentBySlug() {
    yield takeLatest(getContentSlug.toString(), fetchContentSlug);
}

export function* onFetchSeriesBySlugRequest() {
    yield takeLatest(getSeriesSlug.toString(), fetchSeriesSlug);
}

export function* videoSagas() {
    yield all([
        call(onFetchContentStart), 
        call(onFetchSeriesRequest),
        call(onFetchCatchupRequest),
        call(onFetchContentBySlug),
        call(onFetchSeriesBySlugRequest)
    ]);
}
