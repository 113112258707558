import { API } from 'utils/api.config';

export async function getResponsePayment() {
  return await API.get(`/api/v2/packages`);
}
export async function getResponsePaymentByCountry(country_code) {
  return await API.get(`/api/v2/packages?country=${country_code}`);
}
export async function getUserLocation() {
  return await API.get(`/api/user/location`);
}
// export async function getContentById(id, type) {
//   return await API.get(`api/artist/page/details/${id}?page=1&type=${type}`);
// }
