export default {
  COUPON_SIGN_IN_START: 'COUPON_SIGN_IN_START',
  GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
  FACEBOOK_SIGN_IN_START: 'FACEBOOK_SIGN_IN_START',
  EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
  PHONE_NUMBER_SIGN_IN_START: 'PHONE_NUMBER_SIGN_IN_START',
  PHONE_NUMBER_SIGN_IN_START_BD: 'PHONE_NUMBER_SIGN_IN_START_BD',
  SENT_OTP_SUCCESS: 'SENT_OTP_SUCCESS',
  PHONE_NUMBER_VERIFICATION_CONFIRMATION_START: 'PHONE_NUMBER_VERIFICATION_CONFIRMATION_START',
  PHONE_NUMBER_VERIFICATION_CONFIRMATION_SUCCESS: 'PHONE_NUMBER_VERIFICATION_CONFIRMATION_SUCCESS',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  CHECK_USER_SESSION: 'CHECK_USER_SESSION',
  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
  SIGN_UP_START: 'SIGN_UP_START',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  GET_PROFILE: 'GET_PROFILE',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
  GET_USER_LOCATION: 'GET_USER_LOCATION',
  GET_USER_LOCATION_SUCCESS: 'GET_USER_LOCATION_SUCCESS',
  GET_USER_LOCATION_FAILURE: 'GET_USER_LOCATION_FAILURE',
  ACTION_RESOLVE: 'ACTION_RESOLVE',
  ANONYMOUS_USER: 'ANONYMOUS_USER',
  IS_OTP_VERIFICATION_LOADING: 'IS_OTP_VERIFICATION_LOADING',
  OTP_VERIFICATION_ERROR: 'OTP_VERIFICATION_ERROR',
  OTP_VERIFICATION_SUCCESS: 'OTP_VERIFICATION_SUCCESS',
  SET_VERIFICATION_ERROR: 'SET_VERIFICATION_ERROR'
};
