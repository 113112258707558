import React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

const AdsComponent = ({ fluid }) => {
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle;
        adsbygoogle.push({});
      } catch (e) {
        console.error(e);
      }
    };

    let interval = setInterval(() => {
      if (window.adsbygoogle) {
        pushAd();
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval);
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const adsHtml = window?.google_adsense?.ad_banner_horizontal;
  if (!adsHtml) return null;
  if (fluid) {
    return (
      <AdsWrapper>
        <ins
          className="adsbygoogle"
          style={{
            display: 'block',
            width: '80%',
            maxWidth: 700
          }}
          data-ad-format="fluid"
          data-ad-layout-key="-fb+5w+4e-db+86"
          data-ad-client={adsHtml?.data_ad_client}
          data-ad-slot={adsHtml?.fluid_ad_slot}
        />
      </AdsWrapper>
    );
  }

  return (
    <AdsWrapper>
      <ins
        className="adsbygoogle"
        style={{
          display: 'inline-block',
          width: '90%',
          maxWidth: 900,
          height: '140px'
        }}
        data-ad-client={adsHtml?.data_ad_client}
        data-ad-slot={adsHtml?.data_ad_slot}
        // data-ad-format="auto"
        // data-full-width-responsive="true"
      />
    </AdsWrapper>
  );
};

const AdsWrapper = styled.div`
  margin: 0px auto;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  max-height: 145px;

  /* ins.adsbygoogle[data-ad-status='unfilled'] {
    display: none !important;
  } */
`;

export default AdsComponent;
