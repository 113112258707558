import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
    onFailure,
    onSuccess,
    getAllRecommendation,
    getRecommendation
} from './recommendation.slice';
import { getContentByRecommendation, getAllRecommendationApi } from './recommendation.utils';

export function* fetchRecommendedContent({ payload }) {
    try {
        const { data } = yield call(getContentByRecommendation, { payload });

        yield put(onSuccess(data));
    } catch (error) {
        yield put(onFailure(error.toString()));
    }
}
export function* fetchAllRecommendation({ payload }) {
    try {
        const { data } = yield call(getAllRecommendationApi, { type: payload });
        yield put(onSuccess(data));
    } catch (error) {
        yield put(onFailure(error.toString()));
    }
}

export function* onRecommendedContentRequest() {
    yield takeLatest(getRecommendation.toString(), fetchRecommendedContent);
}
export function* onAllRecommendedContentRequest() {
    yield takeLatest(getAllRecommendation.toString(), fetchAllRecommendation);
}

export function* recommendationSagas() {
    yield all([call(onRecommendedContentRequest), call(onAllRecommendedContentRequest)]);
}
