import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  type: null,
  visible: false
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state, action) {
      state.data = action.payload.data;
      state.type = action.payload.type;
      state.visible = true;
    },
    closeModal(state, action) {
      state.data = null;
      state.type = null;
      state.visible = false;
    }
  }
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
