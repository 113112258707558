import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  items: [],
  location: '',
  loading: true,
  error: null
};
const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    getResponse(state, action) {
      state.loading = true;
    },
    fetchResponseSuccess(state, action) {
      state.items = action.payload;
      state.loading = false;
    },
    getResponseByCountry(state, action) {
      state.loading = true;
    },
    fetchResponseSuccessByCountry(state, action) {
      state.items = action.payload;
      state.loading = false;
    },
    getLocation(state, action) {
      state.loading = true;
    },
    getLocationSuccess(state, action) {
      state.location = action.payload;
      state.loading = false;
    },

    onFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});
export const {
  getResponse,
  fetchResponseSuccess,
  getResponseByCountry,
  fetchResponseSuccessByCountry,
  getLocation,
  getLocationSuccess,
  onFailure
} = paymentSlice.actions;
export default paymentSlice.reducer;
