import { all, call } from 'redux-saga/effects';
import { favouriteSagas } from './favourite/favourite.sagas';
import { auxiliarySagas } from './auxilary/auxiliary.sagas';
import { paymentSagas } from './payment/payment.sagas';
import { artistSagas } from './artist/artist.sagas';
import { userSagas } from './user/user.sagas';
import { bannerSagas } from './banner/banner.sagas';
import { categorySagas } from './category/category.sagas';
import { audioSagas } from './audio/audio.sagas';
import { videoSagas } from './video/video.sagas';
import { recommendationSagas } from './recommendation/recommendation.sagas';
import { wishlistSagas } from './wishlist/wishlist.sagas';
import { searchSagas } from './search/search.sagas';
import { channelSagas } from './channel/channel.sagas';
import { subscriptionSagas } from './subscription/subscription.sagas';
import { feedSagas } from './feed/feed.sagas';
function* rootSaga() {
  yield all([
    call(feedSagas),
    call(favouriteSagas),
    call(auxiliarySagas),
    call(paymentSagas),
    call(artistSagas),
    call(userSagas),
    call(bannerSagas),
    call(categorySagas),
    call(audioSagas),
    call(videoSagas),
    call(recommendationSagas),
    call(wishlistSagas),
    call(searchSagas),
    call(channelSagas),
    call(subscriptionSagas)
  ]);
}

export default rootSaga;
