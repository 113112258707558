import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getAllFeeds, onSuccess, onFailure } from './feed.slice';
import { getAllFeedsApi } from './feed.utils';
export function* fetchFeeds({ payload }) {
  try {
    const { data } = yield call(getAllFeedsApi, payload);

    yield put(onSuccess(data));
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}

export function* onFetchFeeds() {
  yield takeLatest(getAllFeeds.toString(), fetchFeeds);
}

export function* feedSagas() {
  yield all([call(onFetchFeeds)]);
}
