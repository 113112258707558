import { createSlice } from '@reduxjs/toolkit';


let savedTheme = localStorage.getItem('theme');

const defaultTheme = window.platform_info.theme;

const initialState = {
  theme: savedTheme || defaultTheme
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    themeToggle(state, action) {
      state.theme = action.payload;
    }
  }
});

export const { themeToggle } = themeSlice.actions;
export default themeSlice.reducer;
