import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    videoPlaylist: null,
    selectedItems: [],
    video: [],
    series: [],
    catchup: [],
    audio: [],
    album: [],
    loading: true,
    error: null
};
const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        createWishlist(state, action) {
            state.loading = true;
        },
        createWishlistSuccess(state, action) {
            state.loading = false;
            state.items = [...state.items, action.payload];
        },
        createWishlistFailure(state, action) {
            state.error = action.payload;
        },
        addItemToWishlist(state, action) {
            state.loading = true;
        },
        addItemToWishlistSuccess(state, action) {
            state.loading = false;
        },
        addItemToWishlistFailure(state, action) {
            state.error = action.payload;
        },
        displayWishlists(state, action) {
            state.loading = true;
            // state.items = [];
        },
        fetchWishlistsSuccess(state, action) {
            state.loading = false;
            state[action.payload.type] = action.payload.data;
        },
        fetchWishlistsFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getSelectedWishlist(state, action) {
            state.loading = true;
        },
        getSelectedWishlistSuccess(state, action) {
            state.selectedItems = action.payload;
            state.loading = false;
        },
        getSelectedWishlistFailure(state, action) {
            const { displayType, issueId = null } = action.payload;
            state.displayType = displayType;
            state.issueId = issueId;
        }
    }
});
export const {
    createWishlist,
    createWishlistSuccess,
    createWishlistFailure,
    addItemToWishlist,
    addItemToWishlistSuccess,
    addItemToWishlistFailure,
    displayWishlists,
    getSelectedWishlist,
    getSelectedWishlistSuccess,
    getSelectedWishlistFailure,
    fetchWishlistsSuccess,
    fetchWishlistsFailure
} = wishlistSlice.actions;
export default wishlistSlice.reducer;
