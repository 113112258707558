import { API } from 'utils/api.config';

export async function getContentByRecommendation({ payload }) {
    return await API.get(`api/content/recommendation`, {
        params: {
            contentId: payload.contentId,
            type: payload.type 
        }
    });
}
export async function getAllRecommendationApi(type) {
    return await API.get(`api/content/recommendation/seemore`, {
        params: type
    });
}
