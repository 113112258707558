import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  userProfiles: {}
};

const userSubSlice = createSlice({
  name: 'userSub',
  initialState,
  reducers: {
    setUserSub(state, action) {
      state.userProfiles = action.payload;
    }
  }
});

export const { setUserSub } = userSubSlice.actions;
export default userSubSlice.reducer;
