import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
  getSubscription,
  onGetSubscriptionSuccess,
  onGetSubscriptionFailure,
  onPostSubscribe,
  onPostSubscribeSuccess,
  onPostSubscribeFailure,
  onUnsubscribe,
  onUnsubscribeSuccess,
  onUnsubscribeFailure
} from './subscription.slice';
import {
  getSubscriptionPackagesApi,
  postSubscriptionPackagesApi,
  unsubscribeApi
} from './subscription.utils';
export function* fetchSubscriptionPackages() {
  try {
    const { data } = yield call(getSubscriptionPackagesApi);
    yield put(onGetSubscriptionSuccess(data));
  } catch (error) {
    yield put(onGetSubscriptionFailure(error.toString()));
  }
}
export function* doUnsubscribe({ payload }) {
  try {
    const data = yield call(unsubscribeApi, payload);
    yield put(onUnsubscribeSuccess(data));
  } catch (error) {
    yield put(onUnsubscribeFailure(error.toString()));
  }
}
export function* postSubscribeCall({ payload }) {
  try {
    const { data } = yield call(postSubscriptionPackagesApi, payload);
    yield put(onPostSubscribeSuccess(data));
  } catch (error) {
    yield put(onPostSubscribeFailure(error.toString()));
  }
}
export function* onFetchSubscriptionPackages() {
  yield takeLatest(getSubscription.toString(), fetchSubscriptionPackages);
}
export function* onPostSubscribeCall() {
  yield takeLatest(onPostSubscribe.toString(), postSubscribeCall);
}
export function* onUnsubscribeCall() {
  yield takeLatest(onUnsubscribe.toString(), doUnsubscribe);
}
export function* subscriptionSagas() {
  yield all([
    call(onFetchSubscriptionPackages),
    call(onPostSubscribeCall),
    call(onUnsubscribeCall)
  ]);
}
