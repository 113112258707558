import { takeLatest, put, all, call } from 'redux-saga/effects';
import * as actionTypes from './category.types';
import {
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
    fetchCategorySuccess,
    fetchCategoryFailure
} from './category.actions';
import { getCategory, getContentByCategoryId, getContentByCategorySlug } from './category.utils';
export function* fetchCategories({ payload }) {
    try {
        const { data } = yield call(getCategory, payload);

        yield put(fetchCategoriesSuccess({ data, type: payload.type }));
    } catch (error) {
        yield put(fetchCategoriesFailure(error.toString()));
    }
}
export function* fetchCategory({ payload }) {
    try {
        const { data } = yield call(getContentByCategoryId, payload);

        yield put(fetchCategorySuccess(data));
    } catch (error) {
        yield put(fetchCategoryFailure(error.toString()));
    }
}
export function* fetchCategorySlug({ payload }) {
    try {
        const { data } = yield call(getContentByCategorySlug, payload);

        yield put(fetchCategorySuccess(data));
    } catch (error) {
        yield put(fetchCategoryFailure(error.toString()));
    }
}
export function* onFetchCategoriesStart() {
    yield takeLatest(actionTypes.FETCH_CATEGORIES_START, fetchCategories);
}
export function* onFetchCategoryRequest() {
    yield takeLatest(actionTypes.FETCH_CATEGORY_REQUEST, fetchCategory);
}
export function* onFetchCategorySlugRequest() {
    yield takeLatest(actionTypes.FETCH_CATEGORY_SLUG_REQUEST, fetchCategorySlug)
}
export function* categorySagas() {
    yield all([call(onFetchCategoriesStart), call(onFetchCategoryRequest), call(onFetchCategorySlugRequest)]);
}
