import { API } from 'utils/api.config';

export async function addFavourite(contentId) {
  return await API.post(`/api/addFavorites`, {
    contentId: contentId
  });
}
export async function getAllFavourite(type, page) {
  return await API.get(`/api/getAllFavorites`, {
    params: {
      page,
      type
    }
  });
}
export async function removeAllFavourite(type) {
  return await API.post(`/api/removeAllFavorites`, {
    type
  });
}
export async function removeFavourite(contentId) {
  return await API.post(`/api/removeFavorites`, {
    contentId: contentId
  });
}
export async function seeAllFavourite(type) {
  return await API.get(`/api/getAllFavorites/seemore`, {
    params: {
      type
    }
  });
}
export async function getFavouriteTrue(id) {
  return await API.get(`/api/getFavorites/${id}`);
}
