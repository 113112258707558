import React from 'react';
import styled from 'styled-components';
const NotFoundComponent = () => {
  return (
    <Wrapper>
      <div className="big_text">404</div>
      <div className="text">not found</div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 80vh;
  .big_text {
    font-weight: 600;
    font-size: 5rem;
    letter-spacing: 5px;
    line-height: 5rem;
  }
  .text {
    text-transform: uppercase;
    font-size: 3rem;
  }
`;
export default NotFoundComponent;
