import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  items: [],
  seeMore: [],
  loading: true,
  error: null
};
const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    getChannels(state, action) {
      state.loading = true;
    },
    getChannelsSeeMore(state, action) {
      state.loading = true;
    },
    onSuccess(state, action) {
      state.items = action.payload;
      state.loading = false;
    },
    onSuccessSeeMore(state, action) {
      state.seeMore = action.payload;
      state.loading = false;
    },
    onFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});
export const {
  getChannels,
  onSuccess,
  onFailure,
  getChannelsSeeMore,
  onSuccessSeeMore
} = channelSlice.actions;
export default channelSlice.reducer;
