import { takeLatest, put, all, call } from 'redux-saga/effects';
import { onFailure, fetchSearchSuccess, getSearchResults } from './search.slice';
import { search2 } from './search.utils';

export function* fetchSearchedContent({ payload: { page, title, type } }) {
  try {
    const { data } = yield call(search2, page, title, type);

    yield put(fetchSearchSuccess(data));
  } catch (error) {
    yield put(onFailure(error.toString()));
  }
}

export function* onSearchContentRequest() {
  yield takeLatest(getSearchResults.toString(), fetchSearchedContent);
}

export function* searchSagas() {
  yield all([call(onSearchContentRequest)]);
}
