import React from 'react';
import { useHistory } from 'react-router-dom';
import VideoPlayer from '../player';
import firebase from 'utils/firebase';

const VideoJs = ({
  videoUrl,
  currentTrack,
  currentUser,
  userProfiles,
  setEpisodeIndex,
  setSeriesIndex,
  series,
  episodeIndex,
  seriesLength,
  seriesIndex,
  subtitle,
  id: seriesId,
  setVideoDuration,
  trailerModal,
  setTrailerEnd,
  from,
  playerInstanceReady
}) => {
  const playerRef = React.useRef(null);
  const history = useHistory();
  const [url, setUrl] = React.useState(videoUrl);
  const [videoEnd, setVideoEnd] = React.useState(false);
  const [showSkipTrailerBtn, setShowSkipTrailerBtn] = React.useState(false);
  const title = window?.platform_info?.title;
  const [playerReady, setPlayerReady] = React.useState(false);
  const [playerFullScreen, setPlayerFullScreen] = React.useState(false);

  const subTitles =
    subtitle?.length > 0 &&
    subtitle.map((sub) => {
      console.log(`https://subtitles.rockstreamer.com/${sub.path}`);
      return {
        src: `https://subtitles.rockstreamer.com/${sub.path}`,
        kind: 'subtitles',
        srclang: sub.languageCode,
        label: sub.languageCode
      };
    });
  React.useEffect(() => {
    setUrl(videoUrl);
  }, [videoUrl]);

  // React.useEffect(() => {
  //   let currentTime = 0;
  //   const intervalId = setInterval(() => {
  //     if (playerRef.current && currentTrack?.premium && !userProfiles?.subscribe) {
  //       currentTime = playerRef.current.currentTime();
  //     }
  //     if (currentTime > 30) {
  //       setShouldPremiumStop(true);
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  React.useEffect(() => {
    if (videoEnd === true) {
      if (currentTrack?.type === 'catchupTV' || currentTrack?.type === 'catchupTVEpisode') {
        setEpisodeIndex((prev) => prev + 1);
        history.push({
          pathname: `/catchupTV/${seriesId}`,
          search: '?' + new URLSearchParams({ episode_index: episodeIndex + 2 }).toString()
        });
      } else {
        setEpisodeIndex((prev) => prev + 1);
        // to make backward compatible with old query param based series player
        if (!from) {
          history.push({
            pathname: `/series/${seriesId}`,
            search:
              '?' +
              new URLSearchParams({ season_index: 1 }).toString() +
              '&' +
              new URLSearchParams({ episode_index: episodeIndex + 2 }).toString()
          });
        }
        if (episodeIndex == series.contents.length - 1) {
          setSeriesIndex((prev) => prev + 1);
          setEpisodeIndex(0);
          // to make backward compatible with old query param based series player
          if (!from) {
            history.push({
              pathname: `/series/${seriesId}`,
              search:
                '?' +
                new URLSearchParams({ season_index: seriesIndex + 2 }).toString() +
                '&' +
                new URLSearchParams({ episode_index: 1 }).toString()
            });
          }

          if (seriesIndex == seriesLength - 1) {
            // to make backward compatible with old query param based series player
            if (!from) {
              history.push({
                pathname: `/series/${seriesId}`,
                search:
                  '?' +
                  new URLSearchParams({ season_index: 1 }).toString() +
                  '&' +
                  new URLSearchParams({ episode_index: 1 }).toString()
              });
            }
            setSeriesIndex(0);
            setEpisodeIndex(0);
          }
        }
      }
      setVideoEnd(false);
    }
  }, [videoEnd]);

  function onSkipEpisode() {
    setEpisodeIndex((prev) => prev + 1);
    if (!from) {
      history.push({
        pathname: `/series/${seriesId}`,
        search:
          '?' +
          new URLSearchParams({ season_index: 1 }).toString() +
          '&' +
          new URLSearchParams({ episode_index: episodeIndex + 2 }).toString()
      });
    }
    if (episodeIndex == series.contents.length - 1) {
      setSeriesIndex((prev) => prev + 1);
      setEpisodeIndex(0);
      if (!from) {
        history.push({
          pathname: `/series/${seriesId}`,
          search:
            '?' +
            new URLSearchParams({ season_index: seriesIndex + 2 }).toString() +
            '&' +
            new URLSearchParams({ episode_index: 1 }).toString()
        });
      }

      if (seriesIndex == seriesLength - 1) {
        if (!from) {
          history.push({
            pathname: `/series/${seriesId}`,
            search:
              '?' +
              new URLSearchParams({ season_index: 1 }).toString() +
              '&' +
              new URLSearchParams({ episode_index: 1 }).toString()
          });
        }
        setSeriesIndex(0);
        setEpisodeIndex(0);
      }
    }
  }

  React.useEffect(() => {
    if (
      currentTrack?.premium &&
      !userProfiles?.subscribe &&
      currentTrack?.trailer &&
      !currentTrack?.tvod
    ) {
      setShowSkipTrailerBtn(true);
    }
  }, []);

  React.useEffect(() => {
    return () => {
      if (showSkipTrailerBtn == true) {
        setShowSkipTrailerBtn(false);
      }
    };
  }, []);

  const onHideSkipTrailer = () => {
    setTrailerEnd(true);
    setShowSkipTrailerBtn(false);
  };

  const autoplayStatus =
    currentTrack?.live || currentTrack?.type == 'tv'
      ? true
      : !userProfiles?.subscribe
      ? false
      : !trailerModal;

  const overrideNative = false;
  const videoJsOptions = {
    // lookup the options in the docs for more options
    hls: {
      overrideNative: overrideNative
    },
    nativeVideoTracks: !overrideNative,
    nativeAudioTracks: !overrideNative,
    nativeTextTracks: !overrideNative,
    autoplay: autoplayStatus,
    // autoplay: false,
    playsinline: true,
    controls: true,
    responsive: true,
    poster: `${process.env.REACT_APP_IMAGE_URL}400xauto/${currentTrack?.thumbnails[0]?.path}`,
    vhs: { overrideNative: true },
    hls: { overrideNative: true },
    sources: [
      {
        src: url,
        type: 'application/x-mpegURL',
        label: 'SD'
      },
      {
        src: url,
        type: 'application/x-mpegURL',
        label: 'HD'
      }
    ],
    userActions: {
      hotkeys: {}
    },
    tracks: subTitles

    // tracks: [
    //   {
    //     src: 'https://kot-politiken.s3-eu-west-1.amazonaws.com/2019/114_en.vtt.txt',
    //     kind: 'subtitles',
    //     srclang: 'en',
    //     label: 'English'
    //   },
    //   { src: 'https://domain/mysub.vtt', kind: 'subtitles', srclang: 'ban', label: 'Bangla' }
    // ]
  };

  const analytics = firebase.analytics();
  const handlePlayerReady = (player) => {
    playerRef.current = player;
    playerInstanceReady(player);
    analytics.logEvent('content_tracking', {
      content_id: currentTrack?.id.toString(),
      platform_name: window.platform_info.api_key,
      content_name: currentTrack?.title,
      type: currentTrack?.type,
      cast: currentTrack?.casts?.map((cast) => cast.name).join(','),
      createdAt: currentTrack?.createdAt,
      user_id: currentUser?.id
    });

    player.on('dispose', () => {
      analytics.logEvent('content_tracking_play', {
        content_id: currentTrack?.id.toString(),
        platform_name: window.platform_info.api_key,
        content_duration: playerRef.current.currentTime()
      });
    });
    player.on('ready', () => {
      console.log('Player is ready');
      setPlayerReady(true);
    });

    player.on('play', () => {
      // setPlayerFullScreen(true);
      // setTimeout(() => {
      //   player.play();
      // }, 1000)
    });

    player.on('fullscreenchange', () => {
      console.log('Checking full screen event');
    });

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('adend', function () {
      console.log('Content resumed');
      // player.play();
      player.muted(false);
      // player.volume(100);
    });
  };

  const onVideoEnd = () => {
    if (currentTrack?.premium && !userProfiles?.subscribe) {
      setTrailerEnd(true);
    }
    analytics.logEvent('content_tracking_play', {
      content_id: currentTrack?.id.toString(),
      platform_name: window.platform_info.api_key,
      content_duration: playerRef.current.duration()
    });
    setEpisodeIndex && setVideoEnd(true);
  };

  return (
    <VideoPlayer
      options={videoJsOptions}
      onEnd={onVideoEnd}
      currentTrack={currentTrack}
      userProfiles={userProfiles}
      onReady={handlePlayerReady}
      setVideoDuration={setVideoDuration}
      onSkipEpisode={onSkipEpisode}
      seriesId={seriesId}
      showSkipTrailerBtn={showSkipTrailerBtn}
      onHideSkipTrailer={onHideSkipTrailer}
      seriesIndex={seriesIndex}
      episodeIndex={episodeIndex}
      from={from}
      setEpisodeIndex={setEpisodeIndex}
      setSeriesIndex={setSeriesIndex}
    />
  );
};

export default VideoJs;
