import { API } from 'utils/api.config';

// export async function getContentById(id) {
//   return await API.get(`apicontent/${id}`);
// }

export async function getContentById(id) {
  return await API.get(`api/v2/content/${id}`);
}

export async function getContentBySlug(slug) {
  return await API.get(`api/v2/content/slug/${slug}`);
}

// export async function getSeriesById(id) {
//   return await API.get(`api/content/series/${id}`);
// }
export async function getSeriesById(id) {
  return await API.get(`api/v2/content/series/${id}`);
}

export async function getSeriesBySlug(slug) {
  return await API.get(`api/v2/content/series/slug/${slug}`);
}

export async function getCatchupById(id) {
  return await API.get(`api/content/catchup/${id}`);
}

export async function checkTvodSubscription(id) {
  return await API.post(`ipn/v1/tvod/checkSub`, {
    content_id: id
  })
}
