import React, { lazy, Suspense, useEffect, useState, useMemo, useContext } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Layout from 'components/layout';
import ErrorBoundary from 'components/ErrorBoundary';
import Loader from 'components/InlineLoader';
import ScrollToTop from 'components/scroll-to-top';
import { toast } from 'react-toastify';
import Progress from 'react-progress';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-multi-carousel/lib/styles.css';
import { Helmet } from 'react-helmet';
import { GlobalStyles } from 'assets/styled/global.styled';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from 'assets/styled/Theme';
import useGaTracker from 'useGatracker';
import CookieConsent from 'react-cookie-consent';
import jwt from 'jwt-decode';
import { setUserSub } from 'redux/userSubInfo/userSub.slice';
import firebase from 'utils/firebase';
import useAnanymousCheck from 'useAnanymousCheck';
import SubscribeRedirect from 'components/settings/SubscribeRedirect';
import { setVastTag } from 'redux/video/video.slice';
import { callbackTypeOne } from 'utils/gp-callbacks';
import VideoDetailSlug from 'pages/VideoDetailSlug';
import SeriesDetailSlug from 'pages/SeriesDetailSlug';

// Dynamic Imports Goes Here.
// 37 routes

// const UserSettings = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "userSettings" */ './settings')));
const SearchPage = lazy(() => lazyRetry(() => import('pages/SearchPage')));
const FavouriteSeeAll = lazy(() => lazyRetry(() => import('pages/FavouriteSeeAll')));
const ScrollToTopButton = lazy(() => lazyRetry(() => import('components/ScrollToTopButton')));
const PremiumModal2 = lazy(() => lazyRetry(() => import('components/settings/PremiumModal2')));
const Package1 = lazy(() => lazyRetry(() => import('components/settings/Package1')));
const ChannelsSeemore = lazy(() => lazyRetry(() => import('pages/ChannelsSeemore')));
const AllPopularArtist = lazy(() => lazyRetry(() => import('pages/AllPopularArtist')));
const Settings = lazy(() => lazyRetry(() => import('pages/Settings')));
const ArtistPageRebuilt = lazy(() => lazyRetry(() => import('pages/ArtistPageRebuilt')));
const CouponAuth = lazy(() => lazyRetry(() => import('components/CouponAuth')));
const Modals = lazy(() => lazyRetry(() => import('components/modals')));
const Login = lazy(() => lazyRetry(() => import('pages/Login')));
const PaymentConfirmation = lazy(() => lazyRetry(() => import('pages/PaymentConfirmation')));
const CallbackPaymentPage = lazy(() => lazyRetry(() => import('pages/CallbackPaymentPage')));
const Home = lazy(() => lazyRetry(() => import('pages/Home')));
const Audios = lazy(() => lazyRetry(() => import('pages/Audios')));
const Videos = lazy(() => lazyRetry(() => import('pages/Videos')));
const AudioDetail = lazy(() => lazyRetry(() => import('pages/AudioDetail')));
const AudioDetailSlug = lazy(() => lazyRetry(() => import('pages/AudioDetailSlug')));
const AlbumDetail = lazy(() => lazyRetry(() => import('pages/AlbumDetail')));
const AlbumDetailSlug = lazy(() => lazyRetry(() => import('pages/AlbumDetailSlug')));
const VideoDetail = lazy(() => lazyRetry(() => import('pages/VideoDetail')));
const ChannelPlay = lazy(() => lazyRetry(() => import('pages/ChannelPlay')));
const SeriesDetail = lazy(() => lazyRetry(() => import('pages/SeriesDetail')));
const CatchupDetail = lazy(() => lazyRetry(() => import('pages/CatchupDetail')));
const CategoryPage = lazy(() => lazyRetry(() => import('pages/CategoryPage')));
const CategoryPageSlug = lazy(() => lazyRetry(() => import('pages/CategoryPageSlug')));
const RecommendationPage = lazy(() => lazyRetry(() => import('pages/RecommendationPage')));
const Favourite = lazy(() => lazyRetry(() => import('pages/Favourite')));
const NotFound = lazy(() => lazyRetry(() => import('pages/NotFound')));
const PrivacyPolicy = lazy(() => lazyRetry(() => import('pages/PrivacyPolicy')));
const TermsOfUse = lazy(() => lazyRetry(() => import('pages/TermsOfUse')));
const AboutCompany = lazy(() => lazyRetry(() => import('pages/AboutCompany')));
const PlaystorePopup = lazy(() => lazyRetry(() => import('components/PlaystorePopup')));
const ContactUs = lazy(() => lazyRetry(() => import('pages/ContactUs')));
const Pricing = lazy(() => lazyRetry(() => import('pages/Pricing')));
const Original = lazy(() => lazyRetry(() => import('pages/Originals')));

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const IndicatorContext = React.createContext();

function IndicatorProvider({ children }) {
  const [active, setActive] = useState(false);
  const value = useMemo(() => ({ active, setActive }), [active, setActive]);
  return <IndicatorContext.Provider value={value}>{children}</IndicatorContext.Provider>;
}

function Indicator() {
  const { active } = useContext(IndicatorContext);
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setPercent((percent) => (percent < 100 ? percent + 10 : 100));
    }, 200);
  });
  return active ? <Progress percent={percent} /> : null;
}

function IndicatorFallback() {
  const { setActive } = useContext(IndicatorContext);
  useEffect(() => {
    setActive(true);
    return () => setActive(false);
  });
  return null;
}

function PrivateRoute({ children, ...rest }) {
  const { currentUser, status } = useSelector((state) => state.user);
  if (!currentUser && status === 'pending') return <Loader />;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
toast.configure();

// const addStyle = (url) => {
//   const style = document.createElement('link');
//   style.href = url;
//   style.rel = 'stylesheet';
//   style.async = true;

//   document.head.appendChild(style);
// };

export default function App() {
  const { theme } = useSelector((state) => state.theme);
  const { currentUser } = useSelector((state) => state.user);
  const lokation = useLocation();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const dispatch = useDispatch();
  const history = useHistory();
  const analytics = firebase.analytics();
  const title = window.platform_info.title;
  const iosUser =
    localStorage.getItem(`ios_sub_user_${title}`) && localStorage.getItem(`ios_sub_user_${title}`);
  useAnanymousCheck();

  React.useEffect(() => {
    // Setting Vast Tag
    const vastAdRef = firebase.database().ref('vast_ads');
    vastAdRef.child('web').once('value', (snap) => {
      if (snap.exists()) {
        dispatch(setVastTag(snap.val()));
      }
    });
  }, []);

  let gTag = window.platform_info.google_analytics_key;
  const adsToken = window?.google_adsense?.adsense_code;
  const apikey = window?.platform_info?.api_key;

  useGaTracker();
  // addStyle('http://localhost:9000/rockerzs.css');
  const injectAds = () => {
    const script = document.createElement('script');
    script.src = adsToken;
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';

    document.body.appendChild(script);
  };
  useEffect(() => {
    if (adsToken && apikey === 'iscreen') {
      // injectAds();
    }
  }, [adsToken, apikey]);

  useEffect(() => {
    if (currentUser) {
      console.log('Checking whether new token dispatched for subscription');
      const accessToken = localStorage.getItem(`access_token_r_${title}`);
      const user = jwt(accessToken);
      dispatch(setUserSub(user));
    }
  }, [currentUser]);

  return (
    <IndicatorProvider>
      <Indicator />
      <ScrollToTop />
      <ErrorBoundary>
        <Helmet>
          <title>
            {title} {apikey === 'iscreen' ? '- Movies, Series & Drama' : ''}
          </title>
          <meta
            name="description"
            content={`${title} is a streaming Web App Where you can play your favourite songs and videos`}
          />
          <link rel="icon" type="image/png" href={window.platform_info.favIcon} sizes="16x16" />
          <meta property="og:title" content={title} />

          <meta property="og:image" content={`${window.platform_info.logo}`} />
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${gTag}`}></script>
        </Helmet>

        <PlaystorePopup />
        <Layout>
          <Suspense fallback={<IndicatorFallback />}>
            <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
              <GlobalStyles />
              <ScrollToTopButton />
              {iosUser ? (
                ''
              ) : (
                <CookieConsent
                  location="bottom"
                  buttonText="OK"
                  cookieName="accept-cookie"
                  style={{ background: '#2B373B' }}
                  buttonStyle={{
                    color: '#fff',
                    fontSize: '13px',
                    backgroundColor: 'var(--hover-color)',
                    fontWeight: 'bold'
                  }}
                  expires={150}>
                  This website uses cookies to ensure you get the best experience on our website.{' '}
                  <a
                    target="_blank"
                    href={`https://dev-funprime.rockstreamer.com/privacy-policy`}
                    style={{ color: '#fff' }}>
                    Learn more
                  </a>
                </CookieConsent>
              )}
              <Switch>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/coupon-login">
                  <CouponAuth />
                </Route>
                <Route exact path="/payment/confirm/:status">
                  <PaymentConfirmation />
                </Route>
                <Route exact path="/callback">
                  <CallbackPaymentPage />
                </Route>

                <Route exact path="/videos">
                  <Videos />
                </Route>
                <Route exact path="/songs">
                  <Audios />
                </Route>
                <Route exact path="/feed">
                  <Original />
                </Route>
                {/*
                <Route path="/artist/:id">
                  <ArtistPage />
                </Route> */}
                <Route path="/artist/:slug">
                  <ArtistPageRebuilt />
                </Route>
                <Route exact path="/video/:id">
                  <VideoDetail />
                </Route>
                <Route exact path="/content/video/:slug">
                  <VideoDetailSlug />
                </Route>
                <Route exact path="/content/tv/:slug">
                  <VideoDetailSlug />
                </Route>
                <Route exact path="/channel/:id">
                  <ChannelPlay />
                </Route>
                <Route exact path="/channels">
                  <ChannelsSeemore />
                </Route>
                <Route exact path="/series/:id">
                  <SeriesDetail />
                </Route>
                <Route exact path="/content/series/:slug">
                  <SeriesDetailSlug />
                </Route>
                <Route exact path="/catchupTV/:id">
                  <CatchupDetail />
                </Route>
                <Route exact path="/audio/:id">
                  <AudioDetail />
                </Route>
                <Route exact path="/content/audio/:slug">
                  <AudioDetailSlug />
                </Route>
                <Route exact path="/album/:id">
                  <AlbumDetail />
                </Route>
                <Route exact path="/content/album/:slug">
                  <AlbumDetailSlug />
                </Route>
                <Route exact path="/category/:id">
                  <CategoryPage />
                </Route>
                <Route exact path="/content/category/:slug">
                  <CategoryPageSlug />
                </Route>
                <Route exact path="/recommendation/:type">
                  <RecommendationPage />
                </Route>
                <Route exact path="/favourite">
                  <Favourite />
                </Route>
                <Route exact path="/favourites/:type">
                  <FavouriteSeeAll />
                </Route>
                <Route exact path="/privacy-policy">
                  <PrivacyPolicy />
                </Route>
                <Route exact path="/terms-condition">
                  <TermsOfUse />
                </Route>
                <Route exact path="/about-company">
                  <AboutCompany />
                </Route>
                <Route exact path="/contact-us">
                  <ContactUs />
                </Route>
                <Route exact path="/refund-policy">
                  <Pricing />
                </Route>
                <Route exact path="/allartists">
                  <AllPopularArtist />
                </Route>

                <PrivateRoute exact path="/settings/:name">
                  <Settings />
                </PrivateRoute>
                <PrivateRoute exact path="/search/:type">
                  <SearchPage />
                </PrivateRoute>

                <Route exact path="/subs">
                  <SubscribeRedirect />
                </Route>

                <PrivateRoute exact path="/subscribe">
                  <PremiumModal2 />
                </PrivateRoute>
                <PrivateRoute exact path="/subscribe/:id">
                  <Package1 />
                </PrivateRoute>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="*">
                  <NotFound />
                </Route>
              </Switch>
              <Modals />
            </ThemeProvider>
          </Suspense>
        </Layout>
      </ErrorBoundary>
    </IndicatorProvider>
  );
}
