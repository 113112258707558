import { API } from 'utils/api.config';
export async function search(params) {
  return await API.get(`api/search`, {
    params: params
  });
}
export async function search2(page, title, type) {
  return await API.get(`api/v2/search`, {
    params: {
      page,
      title,
      type
    }
  });
}
